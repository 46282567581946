import { Alert, Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import translate, { LanguageKey } from '../../translate/translate';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import axios from 'axios';

type Props = {};

export interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function SSOLogin({}: Props) {
    const searhParams = new URLSearchParams(window.location.search);
    let _language = searhParams.get('lang') as LanguageKey;
    if (!_language) {
        _language = (searhParams.get('language') as LanguageKey) || 'is';
    }
    const [language, setLanguage] = useState(_language);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string | JSX.Element>('');

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <div style={{ maxWidth: 600, width: '100%', padding: 20 }}>
                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                    <SelectLanguage
                        defaultValue={_language}
                        onChange={(language: LanguageKey) =>
                            setLanguage(language)
                        }
                    ></SelectLanguage>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h2>{translate('sso_title', language)}</h2>
                    <p>{translate('sso_description', language)}</p>
                </div>
                <div>
                    <Form
                        size="large"
                        onFinish={(values) => {
                            setLoading(true);

                            axios
                                .post(
                                    '/verslun/api/get_sso_public/' +
                                        w.uuid +
                                        '/',
                                    {
                                        email: values.email,
                                        language: language,
                                    }
                                )
                                .then((response) => {
                                    setLoading(false);

                                    if (response.data.success) {
                                        setMessage(
                                            translate(
                                                'sso_email_sent',
                                                language
                                            )
                                        );
                                    } else {
                                        setMessage(
                                            translate('error_came_up', language)
                                        );
                                    }
                                });
                        }}
                    >
                        <Form.Item
                            name={'email'}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'email_required',
                                        language
                                    ) as string,
                                },
                                {
                                    type: 'email',
                                    message: translate(
                                        'email_invalid',
                                        language
                                    ) as string,
                                },
                            ]}
                        >
                            <Input
                                placeholder={
                                    translate('email', language) as string
                                }
                            />
                        </Form.Item>

                        {message && (
                            <Alert
                                style={{
                                    marginBottom: 20,
                                }}
                                message={message}
                                type="info"
                                showIcon
                            />
                        )}

                        <Button
                            loading={loading}
                            style={{
                                background: 'black',
                            }}
                            type="primary"
                            block
                            htmlType="submit"
                        >
                            {' '}
                            {translate('get_link', language)}{' '}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}
