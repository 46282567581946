import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import PaymentProviderLogo from '../../../components/PaymentProviderLogo/PaymentProviderLogo';
import Axios from 'axios';
import { SimpleCustomer } from '../../../interfaces/customer';
import { Currency } from '../../../interfaces/product';
import translate, { LanguageKey } from '../../../translate/translate';

const Valitor3DSecure = (props: {
    customer: SimpleCustomer;
    onSuccess: () => void;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}) => {
    const [iframeSrc, setIframeSrc] = useState<string | undefined | null>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [valitorData, setValitorData] = useState<{
        cavv: string;
        xid: string;
        dsTransId: string;
        mdStatus?: string;
    }>();
    const [cardUpdated, setCardUpdated] = useState(false);

    useEffect(() => {
        if (valitorData && !cardUpdated) {
            Axios.post(`/payment/api/update_valitor_card_token/`, {
                card_number: form.getFieldValue('card_number'),
                cvc: form.getFieldValue('cvc'),
                month: form.getFieldValue('month'),
                year: form.getFieldValue('year'),
                cavv: valitorData.cavv,
                xid: valitorData.xid,
                mdStatus: valitorData.mdStatus,
                dsTransId: valitorData.dsTransId,
                customer_uuid: props.customer.uuid,
                currency: props.currencyCode,
            })
                .then(() => {
                    setCardUpdated(true);
                    message.success(translate('card_saved', props.language));
                    props.onSuccess();
                })
                .catch((err) =>
                    message.error(translate('error_came_up', props.language))
                );
        }
    }, [valitorData]);

    useEffect(() => {
        const handlePostMessage = (e: MessageEvent) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setIframeSrc(null);
                console.log(e);
                setValitorData(e.data.valitorMessage);
            }
        };

        window.addEventListener('message', handlePostMessage);

        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, []);

    const shop = props.customer.shop;

    const termsUrl = props.customer.shop?.terms_url
        ? shop.terms_url
        : '/skilmalar/' + (shop?.uuid || '');

    return (
        <>
            <div>
                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    onFinish={(formValues) => {
                        console.log(formValues);

                        Axios.post(`/payment/api/valitor_three_d_secure/`, {
                            cardNumber: formValues.card_number,
                            expMonth: formValues.month,
                            expYear: '20' + formValues.year,
                            customer_uuid: props.customer.uuid,
                        })
                            .then((rsp) => {
                                setIframeSrc(
                                    rsp.data.res.cardVerificationRawResponse
                                );
                            })
                            .catch((err) => {
                                setLoading(false);
                                try {
                                    message.error(err.response.data.error);
                                } catch (error) {
                                    message.error(
                                        translate(
                                            'error_came_up',
                                            props.language
                                        )
                                    );
                                }
                            });
                    }}
                >
                    <Form.Item
                        name="card_number"
                        label={translate('card_number', props.language)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.language
                                ),
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder={
                                translate(
                                    'card_number',
                                    props.language
                                ) as string
                            }
                            size="large"
                            autoComplete="cc-number"
                        />
                    </Form.Item>

                    <div>
                        <Form.Item
                            name="month"
                            label={translate('month', props.language)}
                            style={{
                                display: 'inline-block',
                                width: 'calc(33.333%)',
                            }}
                            rules={[
                                {
                                    len: 2,
                                    message: translate(
                                        'should_contain_two',
                                        props.language
                                    ),
                                },
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        props.language
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={
                                    translate('month', props.language) as string
                                }
                                maxLength={2}
                                autoComplete="cc-exp-month"
                            />
                        </Form.Item>

                        <Form.Item
                            name="year"
                            label={translate('year', props.language)}
                            style={{
                                display: 'inline-block',
                                width: 'calc(33.333% - 12px)',
                                marginLeft: '12px',
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        props.language
                                    ),
                                },
                                {
                                    len: 2,
                                    message: translate(
                                        'should_contain_two',
                                        props.language
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                maxLength={2}
                                placeholder={
                                    translate('year', props.language) as string
                                }
                                autoComplete="cc-exp-year"
                            />
                        </Form.Item>

                        <Form.Item
                            name="cvc"
                            label="CVC"
                            style={{
                                display: 'inline-block',
                                width: 'calc(33.333% - 12px)',
                                marginLeft: '12px',
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        props.language
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                maxLength={4}
                                placeholder="CVC"
                                autoComplete="cc-csc"
                            />
                        </Form.Item>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <div>
                                        {translate('agree', props.language)}{' '}
                                        <a
                                            href={termsUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {translate('terms', props.language)}
                                        </a>
                                    </div>
                                </div>
                            }
                            name="terms_checkbox"
                            valuePropName="checked"
                            initialValue={false}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'please_agree_to_terms',
                                        props.language
                                    ) as string,
                                    validator: (_, value) => {
                                        if (value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error(
                                                translate(
                                                    'please_agree_to_terms',
                                                    props.language
                                                ) as string
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input size="large" type="checkbox" />
                        </Form.Item>

                        {shop && (
                            <span style={{ maxWidth: 70 }}>
                                <PaymentProviderLogo
                                    shop={shop}
                                    maxWidth={70}
                                    margin={'10px auto 5px auto'}
                                ></PaymentProviderLogo>
                            </span>
                        )}
                    </div>

                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            className="btn-success"
                            block
                            size="large"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            {translate('confirm', props.language)}
                        </Button>
                    </Form.Item>
                </Form>

                <div
                    style={{
                        margin: 'auto',
                        textAlign: 'center',
                        borderRadius: '10px',
                    }}
                >
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/master_card.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/maestro.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/visa.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/visa_electron.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/union_pay.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/jcb.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/diners_club.svg"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/discover.png"
                        alt=""
                    />
                </div>
            </div>

            {iframeSrc && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={iframeSrc}
                />
            )}
        </>
    );
};

export default Valitor3DSecure;
