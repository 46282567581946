import React from 'react';
import SaltPay from '../../components/SaltPay/SaltPay';
import { Layout, Row, Col, Card, Tabs, Input, Button } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import ShopClaimsCreatedOverview from '../../components/ShopClaimsCreatedOverview/ShopClaimsCreatedOverview';
import ShopClaimPaymentOverview from '../../components/ShopClaimPaymentsOverview/ShopClaimPaymentsOverview';
import CardRequests from './CardRequests/CardRequests';

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

const { Content } = Layout;

export default function PaymentOverview() {
    return (
        <Layout id="layout_wrapper">
            <Content>
                <Row gutter={[10, 10]} className="main_row">
                    <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={16}>
                        <div
                            className="text-center"
                            style={{ fontSize: 32, margin: 20 }}
                        >
                            <div>Greiðslur</div>
                        </div>

                        <Tabs centered>
                            <TabPane tab="Kortafærslur" key="card_payments">
                                <Card title="Samskipti við færsluhirði">
                                    {w.uuid && (
                                        <CardRequests
                                            uuid={w.uuid}
                                        ></CardRequests>
                                    )}
                                </Card>
                            </TabPane>
                            {w.me === 1 && (
                                <TabPane
                                    tab="Bankagreiðslur"
                                    key="bank_payments"
                                >
                                    <Card
                                        title={
                                            <>
                                                <img
                                                    style={{ height: 65 }}
                                                    src="/static/images/svg/islandsbanki_kassi.svg"
                                                    alt=""
                                                />{' '}
                                                Íslandsbanki
                                            </>
                                        }
                                    >
                                        {w.uuid && (
                                            <ShopClaimPaymentOverview
                                                shopUUID={w.uuid}
                                            ></ShopClaimPaymentOverview>
                                        )}
                                    </Card>
                                </TabPane>
                            )}

                            {w.me === 1 && (
                                <TabPane
                                    tab="Repeat bankagreiðslur"
                                    key="repeat_bank_payments"
                                >
                                    <Card
                                        title={
                                            <>
                                                <img
                                                    style={{ height: 65 }}
                                                    src="/static/images/svg/islandsbanki_kassi.svg"
                                                    alt=""
                                                />{' '}
                                                Íslandsbanki
                                            </>
                                        }
                                    >
                                        {w.uuid && (
                                            <ShopClaimPaymentOverview
                                                shopUUID={w.uuid}
                                            ></ShopClaimPaymentOverview>
                                        )}
                                    </Card>
                                </TabPane>
                            )}
                            {w.me === 1 && (
                                <TabPane
                                    tab="Stofnaðar kröfur"
                                    key="bank_claims_created"
                                >
                                    <Card
                                        title={
                                            <>
                                                <img
                                                    style={{ height: 65 }}
                                                    src="/static/images/svg/islandsbanki_kassi.svg"
                                                    alt=""
                                                />{' '}
                                                Íslandsbanki
                                            </>
                                        }
                                    >
                                        {w.uuid && (
                                            <ShopClaimsCreatedOverview
                                                shopUUID={w.uuid}
                                            ></ShopClaimsCreatedOverview>
                                        )}
                                    </Card>
                                </TabPane>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
