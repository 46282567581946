interface FinalTranslation {
    is: string | JSX.Element;
    en: string | JSX.Element;
}

const translations = {
    cancel_subscription: {
        is: 'Hætta í áskrift',
        en: 'Cancel subscription',
    },

    subscription_successfully_created: {
        is: 'Tókst, þú hefur skráð þig í áskrift',
        en: 'Subscription successfully created',
    },
    open_receipt: {
        is: 'Opna kvittun',
        en: 'Open receipt',
    },
    link: {
        is: 'Hlekkur',
        en: 'Link',
    },
    receipt: {
        is: 'Kvittun',
        en: 'Receipt',
    },
    receipts: {
        is: 'Kvittanir',
        en: 'Receipts',
    },
    print: {
        is: 'Prenta',
        en: 'Print',
    },
    description: {
        is: 'Lýsing',
        en: 'Description',
    },
    account_created_message: {
        is: 'Tókst, nú getur þú skráð þig inn og skoðað læst efni 🥳',
        en: 'Success, you can now log in and view restricted content 🥳',
    },
    barely_passes: {
        is: 'Rétt sleppur',
        en: 'Barely passes',
    },
    just_ok: {
        is: 'Fínt',
        en: 'Just ok',
    },

    create_account: {
        is: 'Stofna aðgang',
        en: 'Create account',
    },
    too_short: {
        is: 'Of stutt',
        en: 'Too short',
    },
    good: {
        is: 'Gott',
        en: 'Good',
    },
    strong: {
        is: 'Sterkt',
        en: 'Strong',
    },
    select_password: {
        is: 'Veldu þér lykilorð',
        en: 'Select a password',
    },
    bad: {
        is: 'Slæmt',
        en: 'Bad',
    },
    password: {
        is: 'Lykilorð',
        en: 'Password',
    },
    select_password_message: {
        is: 'Fylltu út netfang og lykilorð hér að neðan til þess að klára að búa til aðganginn þinn. Leggðu lykilorðið á minnið því þú þarf það til þess að skoða læst efni á vefsíðunni.',
        en: 'Fill out your email and password below to complete your account. Remember your password as you will need it to access restricted content on the website.',
    },
    edit_basic_info: {
        is: 'Uppfæra grunnupplýsingar',
        en: 'Edit basic info',
    },
    action: {
        is: 'Aðgerð',
        en: 'Action',
    },
    comment: {
        is: 'Athugasemd',
        en: 'Comment',
    },
    refund: {
        is: 'Endurgreiða',
        en: 'Refund',
    },
    refund_action: {
        is: 'Aðgerð: Endurgreiðsla',
        en: 'Action: Refund',
    },
    card_charged: {
        is: 'Kort rukkað',
        en: 'Card charged',
    },
    applied_for_authorization: {
        is: 'Kallað eftir heimild',
        en: 'Transaction authorized',
    },
    card_saved: {
        is: 'Kort vistað',
        en: 'Card saved',
    },
    refunded: {
        is: 'Búið að endurgreiða',
        en: 'Refunded',
    },
    what_to_change: {
        is: 'Hverju viltu breyta?',
        en: 'What do you want to change?',
    },
    date: {
        is: 'Dagsetning',
        en: 'Date',
    },
    next: {
        is: 'Næst',
        en: 'Next',
    },
    no_cancel_notice: {
        is: 'Enginn uppsagnafrestur',
        en: 'No cancel notice',
    },
    success: {
        is: 'Tókst',
        en: 'Success',
    },
    cancel_notice: {
        is: 'Uppsagnafrestur',
        en: 'Cancel notice',
    },
    subscription_will_end: {
        is: 'Áskriftin mun afvirkjast',
        en: 'Subscription will end',
    },
    cancel: {
        is: 'Hætta',
        en: 'Cancel',
    },
    cancel_reason_required: {
        is: 'Vinsamlegast skráðu ástæðu uppsagnar',
        en: 'Please enter a reason for cancellation',
    },
    please_select_at_least_one: {
        is: 'Vinsamlegast að minnsta kosti eina áskrift',
        en: 'Please select at least one subscription',
    },
    please_select_quantity: {
        is: 'Vinsamlegast veldu magn',
        en: 'Please select quantity',
    },
    cancel_reason: {
        is: 'Ástæða uppsagnar',
        en: 'Reason for cancellation',
    },
    delivery_option: {
        is: 'Afhendingarmáta',
        en: 'Delivery option',
    },
    cant_change_subscription: {
        is: 'Þú getur ekki breytt þessari áskrift',
        en: "You can't change this subscription",
    },
    quantity_must_be_1: {
        is: 'Vinsamlegast veldu magn',
        en: 'Please select quantity',
    },
    select_subscriptions_to_cancel: {
        is: 'Veldu þær áskriftir sem þú hætta í',
        en: 'Select subscriptions you want to cancel',
    },
    change_quantity: {
        is: 'Breyta magni',
        en: 'Change quantity',
    },
    quantity_changed: {
        is: 'Magn áskriftar hefur verið breytt',
        en: 'Subscription quantity has been changed',
    },
    delivery_option_change_error: {
        is: 'Ekki tókst að breyta afhendingarleið, reyndu aftur eða hafðu samband við okkur: repeat@repeat.is',
        en: 'Failed to change delivery option, please try again or contact us: repeat@repeat.is',
    },
    delivery_option_changed: {
        is: 'Afhendingarleið hefur verið breytt',
        en: 'Delivery option has been changed',
    },
    shipping_method_missing: {
        is: 'Afhendingarleið vantar',
        en: 'Shipping method missing',
    },
    subscription_already_inactive: {
        is: 'Þessi áskrift er nú þegar komin í uppsagnaferli: óvirkjast',
        en: 'This subscription is already in the process of being cancelled: becomes inactive',
    },
    details: {
        is: 'Nánar',
        en: 'Details',
    },
    title: {
        is: 'Titill',
        en: 'Title',
    },
    reactivate: {
        is: 'Hætta við að hætta',
        en: 'Reactivate',
    },
    becomes_inactive: {
        is: 'Óvirkjast',
        en: 'Becomes inactive',
    },
    from: {
        is: 'Frá',
        en: 'From',
    },
    powered_by_repeat: {
        is: 'Knúið af Repeat.is',
        en: 'Powered by Repeat.is',
    },
    no_other_subscriptions_available: {
        is: 'Það eru ekki fleiri áskriftarleiðir í boði',
        en: 'There are no other subscriptions available',
    },
    other_subscriptions_available: {
        is: 'Aðrar áskriftarleiðir í boði',
        en: 'Other subscriptions available',
    },
    you_dont_have_inactive_subscriptions: {
        is: 'Þú ert ekki með óvirkar áskriftir',
        en: 'You do not have any inactive subscriptions',
    },
    active_subscriptions: {
        is: 'Virkar áskriftir',
        en: 'Active subscriptions',
    },
    inactive_subscriptions: {
        is: 'Óvirkar áskriftir',
        en: 'Inactive subscriptions',
    },
    no_active_subscriptions: {
        is: 'Þú ert ekki með virka áskrift',
        en: 'You do not have any active subscriptions',
    },
    expires: {
        is: 'Rennur út',
        en: 'Expires',
    },
    currency_not_supported: {
        is: 'Ógildur gjaldmiðill',
        en: 'Currency not supported',
    },
    no_card_on_file: {
        is: 'Ekkert kort á skrá',
        en: 'No card on file',
    },
    card_on_file: {
        is: 'Kortið þitt er á skrá hjá okkur',
        en: 'Your card is on file',
    },
    add: {
        is: 'Bæta við',
        en: 'Add',
    },
    remove: {
        is: 'Fjarlægja',
        en: 'Remove',
    },
    change: {
        is: 'Breyta',
        en: 'Change',
    },
    my_subscription_settings: {
        is: 'Áskriftir - Mitt svæði',
        en: 'My subscription settings',
    },
    first_payment: {
        is: 'Fyrsta greiðsla',
        en: 'First payment',
    },
    payment_cancelled: {
        is: 'Hætt var við greiðslu',
        en: 'Payment was cancelled',
    },
    buyer_information: {
        is: 'Upplýsingar um kaupanda',
        en: 'Buyer information',
    },
    choose: {
        is: 'Veldu',
        en: 'Choose',
    },
    subscription: {
        is: 'Áskrift',
        en: 'Subscription',
    },
    subscriptions: {
        is: 'Áskriftir',
        en: 'Subscriptions',
    },
    one_time_purchase: {
        is: 'Stök kaup',
        en: 'One time purchase',
    },
    my_settings: {
        is: 'Mínar stillingar',
        en: 'My settings',
    },
    payment_information: {
        is: 'Upplýsingar um kaup',
        en: 'Payment information',
    },
    please_fill_out_all_fields: {
        is: 'Vinsamlegast fylltu út alla reiti',
        en: 'Please fill out all fields',
    },
    connected_card: {
        is: 'Kort tengt áskriftum',
        en: 'Card connected to subscriptions',
    },
    go_to_issuer_to_save_card: {
        is: 'Fara á vef færsluhirðis til að vista kort',
        en: 'Go to issuer to save card',
    },
    save_card: {
        is: 'Vista kort',
        en: 'Save card',
    },
    card_information: {
        is: 'Kortaupplýsingar',
        en: 'Card information',
    },
    card_information_is_ready: {
        is: 'Kortaupplýsingar eru tilbúnar',
        en: 'Your card information is ready',
    },
    you_are_redirected_and_back: {
        is: 'Þú ert að fara á vef færsluhirðis til að vista kortið þitt. Þegar þú hefur vistað kortið þá verður þú send/ur til baka á þessa síðu.',
        en: 'You are being redirected to your card issuer to save your card. When you have saved your card you will be redirected back to this page.',
    },
    apart: {
        is: 'fresti',
        en: 'apart',
    },
    redirecing_to_card_issuer: {
        is: 'Færi þig á vef færsluhirðis til að skrá kortið þitt',
        en: 'Redirecting you to your card issuer to save your card',
    },
    card_save_failed: {
        is: 'Ekki tókst að vista kort, vinsamlegast farðu yfir upplýsingarnar.',
        en: 'Failed to save card, please check your information',
    },
    subscription_exists: {
        is: 'Þú ert þegar áskrifandi.',
        en: 'Subscription already exists',
    },
    postal_code_not_accepted: {
        is: 'Því miður er ekki hægt að senda í þetta póstnúmer með sendingamöguleikanum sem þú ert með valinn.',
        en: 'Unfortunately it is not possible to send to this postal code with the shipping method you have selected.',
    },
    secure_repeat_checkout: {
        is: 'Örugg greiðslusíða, Repeat.is',
        en: 'Secure checkout, Repeat.is',
    },
    secure_repeat_gateway: {
        is: 'Örugg kortagátt, Repeat.is',
        en: 'Secure card gateway, Repeat.is',
    },
    applicable_postal_codes_are: {
        is: 'Hægt er að velja',
        en: 'Applicable postal codes are',
    },
    ssid_is_invalid: {
        is: 'Ógild kennitala',
        en: 'Kennitala is invalid',
    },
    error_came_up: {
        is: 'Villa kom upp',
        en: 'Error came up',
    },
    no_items_in_cart: {
        is: 'Karfan þín er tóm',
        en: 'Your cart is empty',
    },
    product_uuid_missing: {
        is: 'Product uuid vantar',
        en: 'Product uuid missing',
    },
    product_does_not_exists: {
        is: 'Vara er ekki til',
        en: 'Product does not exists',
    },
    quantity_missing: {
        is: 'Það vantar magn',
        en: 'Quantity missing',
    },
    dropp_no_home: {
        is: 'Dropp býður ekki upp á heimsendingu á þessu svæði',
        en: 'Dropp does not offer home delivery for this area',
    },
    claim_created: {
        is: 'Krafa stofnuð',
        en: 'Claim created',
    },
    mailing_list_signup: {
        is: 'Skrá mig á póstlista',
        en: 'Sign up for postlist',
    },
    waiting_list_signup_success: {
        is: 'Takk fyrir að skrá þig. Þér hefur verið bætt við listann 😊',
        en: 'Thank you for signing up. You have been added to our waiting list 😊',
    },
    out_of_stock: {
        is: 'Ekki til á lager',
        en: 'Out of stock',
    },
    join_waiting_list: {
        is: 'Skrá mig á biðlista',
        en: 'Join waiting list',
    },
    nothing_found_for_postal_code: {
        is: 'Ekkert fannst fyrir þetta póstnúmer.',
        en: 'Nothing found for selected postal code',
    },
    posturinn_dpt_postal_code: {
        is: 'Póstnúmer',
        en: 'Parcel point zip',
    },
    posturinn_dpo: {
        is: 'Póstbox.',
        en: 'PO Box',
    },
    posturinn_dpt: {
        is: 'Pakkaport',
        en: 'Parcel point',
    },
    posturinn_dpo_postal_code: {
        is: 'Póstnúmer (póstbox)',
        en: 'Zip code (postbox)',
    },
    shipping_method_required: {
        is: 'Þú verður að velja tegund afhendingar.',
        en: 'Shipping method is missing.',
    },
    repeat_confirmation_footer: {
        is: 'Hugbúnaður fyrir áskriftir, endurteknar greiðslur og fleira.',
        en: 'Software for subscriptions, recurring payments and more.',
    },
    delivery_method: {
        is: 'Afhending',
        en: 'Delivery method',
    },
    delivery_method_required: {
        is: 'Þú verður að velja tegund afhendingar',
        en: 'Delivery method is required',
    },
    paid: {
        is: 'Greitt',
        en: 'Paid',
    },
    unpaid: {
        is: 'Ógreitt',
        en: 'Unpaid',
    },
    shipping_price: {
        is: 'Sendingakostnaður',
        en: 'Shipping price',
    },
    product_price: {
        is: 'Kaupverð',
        en: 'Product price',
    },
    please_agree_to_terms: {
        is: 'Vinsamlegast samþykktu skilmálana.',
        en: 'Please agree to our terms before proceeding.',
    },
    each_time: {
        is: 'skiptið',
        en: 'each time',
    },
    order: {
        is: 'Pöntun',
        en: 'Order',
    },
    total: {
        is: 'Heildarverð',
        en: 'Total',
    },
    order_confirmation: {
        is: 'Staðfesting á pöntun',
        en: 'Order confirmation',
    },
    customer: {
        is: 'Viðskiptavinur',
        en: 'Customer',
    },
    order_confirmed: {
        is: 'Pöntun staðfest',
        en: 'Order confirmed',
    },
    order_unpaid: {
        is: 'Þessi pöntun er ógreidd',
        en: 'Order still unpaid',
    },
    card_number: {
        is: 'Kortanúmer',
        en: 'Card number',
    },
    year: {
        is: 'Ár',
        en: 'Year',
    },
    terms: {
        is: 'Skilmála',
        en: 'terms and conditions',
    },
    agree: {
        is: 'Samþykkja',
        en: 'Agree to the',
    },
    month: {
        is: 'Mánuður',
        en: 'Month',
    },
    phone: {
        is: 'Símanúmer',
        en: 'Phone number',
    },
    email: {
        is: 'Netfang',
        en: 'Email',
    },
    town: {
        is: 'Bæjarfélag',
        en: 'Town',
    },
    postal_code: {
        is: 'Póstnúmer',
        en: 'Postal code',
    },
    address: {
        is: 'Heimilisfang',
        en: 'Address',
    },
    name: {
        is: 'Nafn',
        en: 'Name',
    },
    ssid: {
        is: 'Kennitala',
        en: 'Identification number (kennitala)',
    },
    ssid_is_required: {
        is: 'Þú verður að gefa upp kennitölu',
        en: 'Identification number is required',
    },
    payment: {
        is: 'Greiðsla',
        en: 'Payment',
    },
    payments: {
        is: 'Greiðslur',
        en: 'Payments',
    },
    amount: {
        is: 'Upphæð',
        en: 'Amount',
    },
    confirm: {
        is: 'Staðfesta',
        en: 'Confirm',
    },
    price: {
        is: 'Verð',
        en: 'Price',
    },
    quantity: {
        is: 'Magn',
        en: 'Quantity',
    },
    choose_amount: {
        is: 'Velja upphæð',
        en: 'Select amount',
    },
    select_delivery_option: {
        is: 'Veldu afhendingarmáta',
        en: 'Select a delivery option',
    },
    chosen_amount: {
        is: 'Valin upphæð',
        en: 'Chosen amount',
    },
    minimum: {
        is: 'Lágmark',
        en: 'Mininum',
    },
    shipment_frequecy: {
        is: 'Tíðni sendinga',
        en: 'Shipment frequency',
    },
    shipment_frequency_suffix: {
        is: 'fresti',
        en: 'apart',
    },
    months: {
        is: 'mánaða',
        en: 'months',
    },
    weeks: {
        is: 'vikna',
        en: 'weeks',
    },
    buy: {
        is: 'Kaupa',
        en: 'Buy',
    },
    years: {
        is: 'árs',
        en: 'years',
    },
    days: {
        is: 'daga',
        en: 'days',
    },
    name_required: {
        is: 'Þú verður að fylla nafnið þitt út',
        en: 'Your name is required',
    },
    email_required: {
        is: 'Þú verður að gefa upp netfangið þitt',
        en: 'Your email is required',
    },
    card_number_required: {
        is: 'Þú verður að fylla út kortanúmer',
        en: 'Card number is required',
    },
    field_required: {
        is: 'Þú verður að fylla þennan reit út',
        en: 'This field is required',
    },
    choose_date: {
        is: 'Velja dagsetningu',
        en: 'Choose date',
    },
    sign_up_right_away: {
        is: 'Skrá mig strax',
        en: 'Sign me up right away',
    },
    combine_with_existing_subscription: {
        is: 'Sameina með annari áskrift',
        en: 'Combine with existing subscription',
    },
    sign_up_for_subscription: {
        is: 'Skrá mig í áskrift',
        en: 'Sign up for subscription',
    },
    create_new_subscription: {
        is: 'Stofna áskrift',
        en: 'Create subscription',
    },
    today: {
        is: 'Í dag',
        en: 'Today',
    },
    created: {
        is: 'Stofnað',
        en: 'Created',
    },
    choose_delivery_option: {
        is: 'Veldu afhendingarmáta',
        en: 'Choose a delivery option',
    },
    choose_frequency: {
        is: 'Veldu tíðni sendinga',
        en: 'Choose shipment frequency',
    },
    choose_base_date: {
        is: 'Veldu grunn dagsetningu',
        en: 'Choose base date',
    },
    next_step: {
        is: 'Næsta skref',
        en: 'Next step',
    },
    transactions_are_from_date: {
        is: (
            <>
                Áskriftarfærslur eru stofnaðar <strong>frá og með</strong>{' '}
                þessum degi.
            </>
        ),
        en: (
            <>
                Subscriptions are created <strong>from and including</strong>{' '}
                this day.
            </>
        ),
    },
    your_rss_link: {
        is: 'RSS hlekkurinn þinn',
        en: 'Your RSS link',
    },
    your_rss_links: {
        is: 'RSS hlekkirnir þínir',
        en: 'Your RSS links',
    },
    shipment_frequency_tooltip: {
        is: (
            <div style={{ maxWidth: 400 }}>
                <p>
                    Tíðni segir til um hversu langt á að líða á milli sendinga.
                </p>

                <strong>Dæmi</strong>

                <p>
                    Ef þú skráir þig í áskrift <strong>1. janúar</strong> og
                    velur <strong>"2 mánaða fresti" </strong>
                    færðu fyrstu sendinguna <strong>1. janúar</strong>, næst{' '}
                    <strong>1. mars</strong>, næst <strong>1. maí</strong>{' '}
                    o.s.frv.
                </p>
            </div>
        ),
        en: (
            <div style={{ maxWidth: 300 }}>
                <p>
                    Shipment frequency indicates how much time should pass
                    between shipments.
                </p>

                <strong>Example</strong>

                <p>
                    If you sign up on <strong>January 1st</strong> and select{' '}
                    <strong>"2 months"</strong> you will get your first shipment
                    on <strong>January 1st</strong>, next{' '}
                    <strong>March 1st</strong>, next <strong>May 1st</strong>{' '}
                    and so on.
                </p>
            </div>
        ),
    },
    android_podcast_instructions: {
        is: (
            <div>
                <a
                    href="https://play.google.com/store/apps/details?id=com.bambuna.podcastaddict&hl=en&gl=US"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <img
                        style={{
                            width: 60,
                            margin: '0px 0 20px',
                        }}
                        src="/static/images/svg/podcast_addict.svg"
                        alt="Podcast addict logo"
                    />
                </a>
                <p>1. Smelltu Podcasts.</p>
                <p>2. Smelltu á RSS.</p>
                <p>
                    3. Settu hlekkinn þinn inn og smelltu á "Add" (Ekki haka við
                    "Private feed" og "Authentication").
                </p>
                <br></br>
                <p>
                    <strong>Ath.</strong> Þú getur að sjálfsögðu notað önnur
                    hlaðvarps "öpp" ef þau styðja RSS. (Spotify styður ekki RSS)
                </p>
            </div>
        ),
        en: (
            <div>
                <a
                    href="https://play.google.com/store/apps/details?id=com.bambuna.podcastaddict&hl=en&gl=US"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <img
                        style={{
                            width: 60,
                            margin: '0px 0 20px',
                        }}
                        src="/static/images/svg/podcast_addict.svg"
                        alt="Podcast addict logo"
                    />
                </a>

                <p>1. Click on Podcasts.</p>
                <p>2. Click on RSS.</p>
                <p>
                    3. Paste your link and click "Add" (Do not check "Private
                    feed" and "Authentication").
                </p>
                <br></br>
                <p>
                    <strong>Note.</strong> You can of course use other podcast
                    "apps" if they support RSS. (Spotify does not support RSS)
                </p>
            </div>
        ),
    },
    ios_podcast_instructions: {
        is: (
            <div>
                <h4>Opnaðu "Podcasts" appið frá Apple.</h4>
                <img
                    style={{
                        width: 60,
                        margin: '0px 0 20px',
                    }}
                    src="/static/images/svg/ios_podcast.svg"
                    alt="Podcast logo"
                />
                <p>1. Smelltu á "Library".</p>
                <p>2. Smelltu á þrjá punktana uppi í hægra horninu.</p>
                <p>
                    3. Smelltu á "Follow a Show by URL..." og settu hlekkinn
                    þinn inn og smelltu á "Subscribe".
                </p>
                <br></br>
                <p>
                    <strong>Ath.</strong> Þú getur að sjálfsögðu notað önnur
                    hlaðvarps "öpp" ef þau styðja RSS. (Spotify styður ekki RSS)
                </p>
            </div>
        ),
        en: (
            <div>
                <h4>Open "Podcasts" app from Apple.</h4>
                <img
                    style={{
                        width: 60,
                        margin: '0px 0 20px',
                    }}
                    src="/static/images/svg/ios_podcast.svg"
                    alt="Podcast logo"
                />
                <p>1. Click on "Library".</p>
                <p>2. Click on the three dots in the top right corner.</p>
                <p>
                    3. Click on "Follow a Show by URL..." and paste your link
                    and click "Subscribe".
                </p>
                <br></br>
                <p>
                    <strong>Note.</strong> You can of course use other podcast
                    "apps" if they support RSS. (Spotify does not support RSS)
                </p>
            </div>
        ),
    },
    copy_link: {
        is: 'Afrita hlekk',
        en: 'Copy url',
    },
    how_to_add_podcast: {
        is: 'Hvernig nota ég hlekkinn minn?',
        en: 'How do I use my link?',
    },
    get_podcast_addict: {
        is: 'Sæktu "Podcast Addict" appið (frítt)',
        en: 'Get "Podcast Addict" app (free)',
    },
    sold_out_message: {
        is: 'Þessar vörur eru því miður uppseldar og því ekki hægt að klára kaupin',
        en: 'These products are unfortunately sold out and therefore it is not possible to complete the purchase',
    },
    sold_out: {
        is: 'Uppselt',
        en: 'Sold out',
    },
    read_more: {
        is: 'Lesa meira',
        en: 'Read more',
    },
    change_interval_frequency: {
        is: 'Breyta tíðni',
        en: 'Change interval frequency',
    },
    card_error: {
        is: 'Kortaupplýsingarnar virðast vera rangar. Athugaðu þær betur',
        en: 'The card information seems to be incorrect. Please check it again',
    },
    email_invalid: {
        is: 'Ógilt netfang',
        en: 'Email invalid',
    },
    payment_failed: {
        is: 'Greiðsla tókst ekki, athugaðu kortið þitt',
        en: 'Payment failed, please check your card',
    },
    add_on: {
        is: 'Viðbót',
        en: 'Add-on',
    },

    commitment_period: {
        is: 'Binditími',
        en: 'Commitment period ',
    },
    YEAR: {
        is: 'ár',
        en: 'years',
    },
    MONTH: {
        is: 'mánuðir',
        en: 'months',
    },
    WEEK: {
        is: 'vikur',
        en: 'weeks',
    },
    DAY: {
        is: 'dagar',
        en: 'days',
    },
    times: {
        is: 'skipti',
        en: 'times',
    },
    cancelled_by_user: {
        is: 'Hætt við af notanda',
        en: 'Cancelled by user',
    },
    where_to_pickup: {
        is: 'Hvar viltu sækja?',
        en: 'Where do you want to pickup?',
    },
    click_to_enlarge: {
        is: 'Smelltu til að sjá í fullri stærð',
        en: 'Click to enlarge',
    },
    should_contain_two: {
        is: 'Þessi reitur á að innihalda 2 stafi',
        en: 'This field should contain 2 characters',
    },
    gift_certificate_success_message: {
        is: '🥳 Tókst! Til hamingju, gjafabréfið þitt hefur verið virkjað.',
        en: '🥳 Success! Congratulations, your gift certificate has been activated.',
    },
    now_for_the_first_time: {
        is: 'núna í fyrsta skiptið',
        en: 'now for the first time',
    },
    subscription_price_after_that: {
        is: 'Áskriftarverð eftir það',
        en: 'Subscription price after that',
    },
    transaction_count_limit_info: {
        is: 'Þessi áskrift hefur takmarkaðan líftíma:',
        en: 'This subscription has a limited lifetime:',
    },
    select_pickup_location: {
        is: 'Veldu stað til að sækja á',
        en: 'Select pickup location',
    },
    subscription_is_paused: {
        is: 'Þessi áskrift er í pásu',
        en: 'This subscription is paused',
    },
    unpause_subscription: {
        is: 'Kveikja aftur á áskrift',
        en: 'Unpause subscription',
    },
    combined: {
        is: 'Alls',
        en: 'Combined',
    },
    token_expired: {
        is: 'Þessi hlekkur er útrunninn',
        en: 'This link has expired',
    },
    token_invalid: {
        is: 'Ógildur hlekkur',
        en: 'Invalid link',
    },
    token_not_found: {
        is: 'Hlekkur fannst ekki',
        en: 'Link not found',
    },
    rejected_by_card_processor: {
        is: 'Hafnað af banka eða kortaþjónustu',
        en: 'Rejected by bank or card processor',
    },
    cart_is_restricted_to_emails: {
        is: 'Karfan er einungis í boði fyrir fyrirfra ákveðin netföng',
        en: 'Cart is restricted to pre-registered emails',
    },
    cart_is_restricted_to_emails_message: {
        is: 'Ef netfangið þitt er ekki á þeim lista getur þú ekki klárað kaupin.',
        en: 'If your email is not on that list you cannot complete the purchase.',
    },
    cart_is_restricted_to_ssids: {
        is: 'Karfan er einungis í boði fyrir fyrirfram ákveðnar kennitölur',
        en: 'Cart is restricted to pre-registered kennitalas (social identification number)',
    },
    cart_is_restricted_to_ssids_message: {
        is: 'Ef kennitalan þín er ekki á þeim lista getur þú ekki klárað kaupin.',
        en: 'If your kennitala is not on that list you cannot complete the purchase.',
    },
    cant_order_unless_on_list: {
        is: 'Þessi vörur er því miður ekki hægt að panta nema þú sért á lista.',
        en: 'You cannot order these products unless you are on the list.',
    },
    finish_subscription_draft: {
        is: 'Klára áskriftarkaup',
        en: 'Finish buying subscription',
    },
    your_subscription_is_almost_ready: {
        is: 'Áskriftin þín er að verða tilbúin, fylltu formið út til að klára skráninguna.',
        en: 'Your subscription is almost ready, fill out this form to complete your signup.',
    },
    add_addons_to_order: {
        is: 'Bæta við pöntun?',
        en: 'Add to order?',
    },
    add_ons: {
        is: 'Viðbótarvörur',
        en: 'Add-ons',
    },
    add_on_product: {
        is: 'Viðbótarvara',
        en: 'Add-on product',
    },
    confirm_subscription: {
        is: 'Staðfesta áskrift',
        en: 'Confirm subscription',
    },
    use_existing_card: {
        is: 'Nota kort sem er þegar á skrá',
        en: 'Use existing card',
    },
    no_thanks: {
        is: 'Nei takk',
        en: 'No thanks',
    },
    do_you_want_add_ons_with_your_subscription: {
        is: 'Má bjóða þér að bæta við áskriftina?',
        en: 'Do you want add-ons with your subscription?',
    },
    ssid_length_error: {
        is: 'Kennitala þarf að vera 10 stafir',
        en: 'Identification number must be 10 characters',
    },
    add_to_next_delivery: {
        is: 'Bæta við næstu sendingu',
        en: 'Add to next delivery',
    },
    get_link: {
        is: 'Fá sendan hlekk',
        en: 'Get link in email',
    },
    sso_title: {
        is: 'Skráðu þig inn með netfangi',
        en: 'Log in with email',
    },
    sso_description: {
        is: 'Sláðu inn netfangið þitt og við sendum þér hlekk til að skrá þig inn.',
        en: 'Enter your email and we will send you a link to log in.',
    },
    sso_email_sent: {
        is: 'Hlekkur hefur verið sendur á netfangið þitt EF þú átt aðgang. Athugaðu spam möppuna ef þú finnur ekki hlekkinn.',
        en: 'A link has been sent to your email IF you have an account. Check your spam folder if you do not find the link.',
    },
} as const;

// Infer Translations type from the translations object
type Translations = typeof translations;

// Now, TranslationKey is automatically inferred from the keys of the translations object
type TranslationKey = keyof Translations;
export type LanguageKey = keyof FinalTranslation;

export default (
    word: TranslationKey,
    lang: LanguageKey = 'is'
): string | JSX.Element => {
    return translations[word][lang];
};
