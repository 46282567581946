import { Button, Card, Divider, Input, Modal, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { DKInvoice } from './DKTypes';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import AuthContext from '../../authContext';
import dayjs from 'dayjs';
import {
    CodeOutlined,
    DeleteOutlined,
    EyeFilled,
    EyeOutlined,
} from '@ant-design/icons';
import { displayCurrencyPrice } from '../../utils';

export default function DKInvoiceOverview() {
    const [invoices, setInvoices] = useState<DKInvoice[]>([]);
    const [json, setJSON] = useState<DKInvoice | false>(false);
    const [deleteOrder, setDeleteOrder] = useState<string | false>(false);
    const { selectedShop } = useContext(AuthContext);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [salesPerson, setSalesPerson] = useState('');

    useEffect(() => {
        if (selectedShop)
            axios
                .get(
                    `/verslun/api/get_dk_invoice_overview/${selectedShop.uuid}/?from_date=${fromDate}&to_date=${toDate}`
                )
                .then((res) => {
                    console.log(res.data);
                    setInvoices(res.data);
                });
    }, [selectedShop]);

    const columns = [
        {
            title: 'Númer',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            render: (text: string, record: DKInvoice) => (
                <div>{record.RecordID}</div>
            ),
        },
        {
            title: 'Verð með VSK',
            dataIndex: 'price_with_tax',
            key: 'price_with_tax',
            render: (text: string, record: DKInvoice) => (
                <div
                    style={{
                        textTransform: 'capitalize',
                    }}
                >
                    {displayCurrencyPrice(
                        record.TotalAmountWithTax,
                        record.Currency
                    )}
                </div>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (text: string, record: DKInvoice) => (
                <div>{record.CName}</div>
            ),
        },
        // {
        //     title: 'Shipping title',
        //     dataIndex: 'shipping_lines',
        //     key: 'shipping_lines',
        //     render: (text: string, record: DKInvoice) => {
        //         if (record.shipping_lines.length === 0) {
        //             return (
        //                 <div
        //                     style={{
        //                         // Line through if canceled
        //                         textDecoration: record.cancelled_at
        //                             ? 'line-through'
        //                             : 'none',
        //                     }}
        //                 >
        //                     Engin sending
        //                 </div>
        //             );
        //         }

        //         return (
        //             <div
        //                 style={{
        //                     // Line through if canceled
        //                     textDecoration: record.cancelled_at
        //                         ? 'line-through'
        //                         : 'none',
        //                 }}
        //             >
        //                 {record.shipping_lines[0].title}
        //             </div>
        //         );
        //     },
        // },
        // {
        //     title: 'Total price',
        //     dataIndex: 'total_price',
        //     key: 'total_price',
        //     render: (text: string, record: DKInvoice) => (
        //         <div
        //             style={{
        //                 // Line through if canceled
        //                 textDecoration: record.cancelled_at
        //                     ? 'line-through'
        //                     : 'none',
        //             }}
        //         >
        //             {record.total_price} {record.currency}
        //         </div>
        //     ),
        // },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string, record: DKInvoice) => (
                <div>{dayjs(record.InvoiceDate).format('D. MMM - YYYY ')}</div>
            ),
        },
        {
            title: 'Sölumaður',
            dataIndex: 'SalePerson',
        },
        {
            title: 'Skoða JSON',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: DKInvoice) => {
                return (
                    <Button
                        type="text"
                        onClick={() => {
                            setJSON(record);
                        }}
                    >
                        <CodeOutlined />
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Card
                className="basePageCard"
                title={
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{ height: 35, marginRight: 10 }}
                                src="/static/images/svg/dk_logo.svg"
                                alt=""
                            />{' '}
                            - reikningar
                        </div>
                    </div>
                }
            >
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        marginBottom: 10,
                    }}
                >
                    <Input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />

                    <Input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />

                    <Input
                        placeholder="Sölumaður"
                        value={salesPerson}
                        onChange={(e) => {
                            setSalesPerson(e.target.value || '');
                        }}
                    ></Input>

                    <Button
                        onClick={() => {
                            if (selectedShop)
                                axios
                                    .get(
                                        `/verslun/api/get_dk_invoice_overview/${selectedShop.uuid}/?from_date=${fromDate}&to_date=${toDate}&sales_person=${salesPerson}`
                                    )
                                    .then((res) => {
                                        console.log(res.data);
                                        setInvoices(res.data);
                                    });
                        }}
                    >
                        Sækja
                    </Button>
                </div>

                <Table
                    pagination={false}
                    dataSource={invoices.map((i) => ({
                        ...i,
                        key: i.RecordID,
                    }))}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Table
                                    style={{ padding: 10, width: '100%' }}
                                    pagination={false}
                                    dataSource={record.Lines}
                                    columns={[
                                        {
                                            title: 'Vöruheiti',
                                            dataIndex: 'Text',
                                            key: 'Text',
                                        },
                                        {
                                            title: 'Magn',
                                            dataIndex: 'Quantity',
                                            key: 'Quantity',
                                        },
                                        {
                                            title: 'Verð án VSK',
                                            dataIndex: 'UnitPrice',
                                            key: 'UnitPrice',
                                            render: (
                                                text: string,
                                                record: any
                                            ) => (
                                                <div
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {displayCurrencyPrice(
                                                        record.UnitPrice,
                                                        record.Currency || 'ISK'
                                                    )}
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Verð með VSK',
                                            dataIndex: 'UnitPriceWithTax',
                                            key: 'UnitPriceWithTax',
                                            render: (
                                                text: string,
                                                record: any
                                            ) => (
                                                <div
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {displayCurrencyPrice(
                                                        record.UnitPriceWithTax,
                                                        record.Currency || 'ISK'
                                                    )}
                                                </div>
                                            ),
                                        },
                                    ]}
                                ></Table>
                            </div>

                            // <p style={{ margin: 0 }}>
                            //     {record.Lines.map((line) => (
                            //         <div>
                            //             {line.Text}:{' '}
                            //             {displayCurrencyPrice(
                            //                 line.UnitPriceWithTax,
                            //                 record.Currency
                            //             )}
                            //         </div>
                            //     ))}
                            // </p>
                        ),
                        rowExpandable: (record) => true,
                    }}
                ></Table>
            </Card>

            {/* <Modal
                open={deleteOrder !== false}
                onCancel={() => setDeleteOrder(false)}
                title="Eyða pöntun"
                footer={null}
            >
                <p>Ertu viss um að þú viljir eyða þessari pöntun?</p>

                {selectedShop && (
                    <Button
                        onClick={() => {
                            axios
                                .post(
                                    `/verslun/api/delete_shopify_order/${selectedShop.uuid}/`,
                                    {
                                        order_id: deleteOrder,
                                    }
                                )
                                .then(() => {
                                    setDeleteOrder(false);
                                    setOrders(
                                        orders.filter(
                                            (order) =>
                                                order.id.toString() !==
                                                deleteOrder
                                        )
                                    );
                                });
                        }}
                        danger
                    >
                        Eyða
                    </Button>
                )}
            </Modal> */}

            <Modal
                open={json !== false}
                onCancel={() => setJSON(false)}
                title="JSON"
                footer={null}
            >
                {json && (
                    // @ts-ignore
                    <SyntaxHighlighter
                        language="json"
                        style={dracula}
                        showLineNumbers={true}
                    >
                        {json && JSON.stringify(json, null, 2)}
                    </SyntaxHighlighter>
                )}
            </Modal>
        </div>
    );
}
