import { useState, useEffect } from 'react';
import { Form, FormInstance, Input } from 'antd';
import translate from '../../translate/translate';
import DeliverySelector from '../../components/DeliverySelector/DeliverySelector';
import { Shop, InfoToCollect } from './interfaces/repeatCheckoutInterfaces';
import { DeliveryOption, DeliveryConfig } from '../../interfaces/delivery.js';
import CustomFormItems from './CustomFormItems.js';
import Product, { RepeatCheckoutProduct } from '../../interfaces/product.js';
// @ts-ignore
import { Kennitala } from '../../kennitala.js';

type Props = {
    shopUUID: string;
    totalProductPrice: number;
    disableInputs: boolean;
    loading: boolean;
    outOfStock: boolean;
    selectedLanguage: 'is' | 'en';
    deliveryConfig?: DeliveryConfig;
    infoToCollect?: InfoToCollect;
    form: FormInstance<any>;
    onValuesChange: (changedValues: { [key: string]: any }) => void;
    freeDelivery?: boolean;
    products:
        | RepeatCheckoutProduct[]
        | RepeatCheckoutProduct['details'][]
        | Product[];
};

export default function CheckoutFormItems({
    outOfStock,
    loading,
    disableInputs,
    selectedLanguage,
    deliveryConfig,
    infoToCollect,
    shopUUID,
    totalProductPrice,
    form,
    onValuesChange,
    freeDelivery,
    products,
}: Props) {
    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption>();

    const required = [
        {
            required: true,
            message: translate('field_required', selectedLanguage),
        },
    ];

    return (
        <div>
            <Form.Item
                label={translate('name', selectedLanguage)}
                name="name"
                rules={required}
            >
                <Input
                    disabled={disableInputs || loading || outOfStock}
                ></Input>
            </Form.Item>

            <Form.Item
                name="email"
                label={translate('email', selectedLanguage)}
                rules={[
                    ...required,
                    {
                        type: 'email',
                        message: translate('email_invalid', selectedLanguage),
                    },
                ]}
            >
                <Input
                    disabled={disableInputs || loading || outOfStock}
                ></Input>
            </Form.Item>

            {infoToCollect?.includes('ssid') && (
                <Form.Item
                    name="ssid"
                    label={translate('ssid', selectedLanguage)}
                    rules={[
                        ...required,
                        {
                            len: 10,
                            message: translate(
                                'ssid_length_error',
                                selectedLanguage
                            ),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value) {
                                    const kt = new Kennitala(value);
                                    // @ts-ignore
                                    if (!kt.valid) {
                                        return Promise.reject(
                                            new Error('Ógild kennitala!')
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}
                >
                    <Input
                        disabled={disableInputs || loading || outOfStock}
                    ></Input>
                </Form.Item>
            )}

            <CustomFormItems
                parentKey="custom_data"
                products={products}
                selectedLanguage={selectedLanguage}
                whenToCollect="CHECKOUT_PAGE"
            ></CustomFormItems>

            {deliveryConfig && shopUUID && (
                <>
                    <DeliverySelector
                        form={form}
                        totalProductPrice={totalProductPrice}
                        deliveryConfig={deliveryConfig}
                        shopUuid={shopUUID}
                        onDroppChange={(dropp) => {
                            onValuesChange({
                                external_delivery_location_id: dropp?.id,
                            });
                        }}
                        onPikkoloChange={(station) => {
                            onValuesChange({
                                external_delivery_location_id: station?.id,
                            });
                        }}
                        onSelect={(deliveryOption) => {
                            onValuesChange({
                                delivery_option: deliveryOption?.id,
                                shipping_provider:
                                    deliveryOption?.shipping_provider,
                            });

                            setSelectedDeliveryOption(deliveryOption);
                        }}
                        language={selectedLanguage}
                        onValuesChange={onValuesChange}
                        freeDelivery={freeDelivery}
                    ></DeliverySelector>
                </>
            )}

            {(infoToCollect?.includes('address') ||
                selectedDeliveryOption?.requires_home_address) && (
                <>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        name="street"
                        label={translate('address', selectedLanguage)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    selectedLanguage
                                ),
                            },
                        ]}
                    >
                        <Input></Input>
                    </Form.Item>
                    <div className="postalAndTown">
                        <Form.Item
                            labelCol={{ span: 24 }}
                            className="postalInput"
                            name="postal_code"
                            label={translate('postal_code', selectedLanguage)}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        selectedLanguage
                                    ),
                                },
                            ]}
                        >
                            <Input></Input>
                        </Form.Item>

                        <Form.Item
                            className="townInput"
                            name="town"
                            label={translate('town', selectedLanguage)}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        selectedLanguage
                                    ),
                                },
                            ]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </div>
                </>
            )}

            {infoToCollect?.includes('phone') && (
                <Form.Item
                    name="phone"
                    label={translate('phone', selectedLanguage)}
                    rules={[
                        {
                            required: true,
                            message: translate(
                                'field_required',
                                selectedLanguage
                            ),
                        },
                    ]}
                >
                    <Input></Input>
                </Form.Item>
            )}
        </div>
    );
}
