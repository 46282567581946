import { useState, useEffect } from 'react';
import { Modal, Input, Button, message } from 'antd';
import Subscription from '../../interfaces/subscription';
import 'moment/locale/is';
import Axios from 'axios';
import { kr } from '../../utils';
import { OrderProduct } from '../../interfaces/order';

type Props = {
    open: boolean;
    subscriptionProduct: OrderProduct;
    onCancel: Function;
    onSuccess: Function;
};

export default function EditSubscriptionProductQuantityModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(
        props.subscriptionProduct.quantity || 1
    );

    useEffect(() => {}, []);

    return (
        <Modal
            open={props.open}
            maskClosable={false}
            title="Breyta magni"
            footer={false}
            onCancel={() => props.onCancel()}
        >
            <div>
                <strong>Magn</strong>
            </div>
            <Input
                size="large"
                type="number"
                min={0}
                value={quantity}
                onChange={(change) => {
                    setQuantity(parseInt(change.target.value));
                }}
            ></Input>
            <br />
            <Button
                loading={loading}
                onClickCapture={() => {
                    if (!quantity) {
                        message.error('Vinsamlegast fyllið inn gilda tölu');
                        return;
                    }
                    setLoading(true);
                    Axios.patch(
                        `/askrift/api/subscription_product/${props.subscriptionProduct.id}/`,
                        { quantity: quantity }
                    )
                        .then((rsp) => {
                            setLoading(false);
                            message.success(
                                'Magni áskriftar hefur verið breytt'
                            );
                            props.onSuccess(quantity);
                        })
                        .catch((err) => {
                            setLoading(false);
                            message.error('Eitthvað fór úrskeiðis :/');
                        });
                }}
                size="large"
                block
                type="primary"
                className="mt20"
            >
                Vista
            </Button>
        </Modal>
    );
}
