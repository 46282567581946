import React from 'react';
import { Button, Image, Table } from 'antd';
import Product, {
    Currency,
    RepeatCheckoutProduct,
} from '../../../interfaces/product';
import {
    displayCurrencyPrice,
    getPostalName,
    getProductTranslation,
    getSelectedAttributeInfo,
} from '../../../utils';
import translate, { LanguageKey } from '../../../translate/translate';

type Props = {
    addOns: Product[] | RepeatCheckoutProduct['details'][];
    selectedLanguage: LanguageKey;
    currency: Currency['currency_code'];
    onAddonSelected?: (addOn: RepeatCheckoutProduct['details']) => void;
    onAddonRemoved?: (addOn: RepeatCheckoutProduct['details']) => void;
};

export default function ProductAddonsTable({
    addOns,
    selectedLanguage,
    currency,
    onAddonSelected,
    onAddonRemoved,
}: Props) {
    const getProductPrice = (
        product: RepeatCheckoutProduct,
        currency: Currency['currency_code']
    ) => {
        let productPrice = product.details.custom_price
            ? product.details.custom_price
            : product.details.prices.find(
                  (price) => price.currency.currency_code === currency
              )?.amount;

        const extraPrice = product.details.custom_price
            ? 0
            : product.details.attributes?.reduce((acc, attribute) => {
                  const attributeInfo = getSelectedAttributeInfo(
                      attribute.order_attribute,
                      currency
                  );

                  return acc + (attributeInfo?.extraCost || 0);
              }, 0) || 0;

        let finalProductPrice = (productPrice || 0) + extraPrice;

        if (!product.details.custom_price && product.interval_discount) {
            finalProductPrice =
                finalProductPrice -
                (product.interval_discount / 100) * finalProductPrice;
        }

        const discountPercentage = product.details.first_time_discount;
        if (discountPercentage) {
            if (discountPercentage === 100) return 0;

            finalProductPrice =
                finalProductPrice -
                (discountPercentage / 100) * finalProductPrice;
        }

        return finalProductPrice;
    };

    const dataSource = addOns as RepeatCheckoutProduct['details'][];

    return (
        <Table
            showHeader={false}
            dataSource={dataSource}
            columns={[
                {
                    key: 'image',
                    render: (p: RepeatCheckoutProduct['details']) => {
                        return (
                            <Image
                                style={{
                                    width: 50,
                                }}
                                src={
                                    p?.main_image.image ||
                                    // @ts-ignore
                                    (p.main_image as string)
                                }
                            ></Image>
                        );
                    },
                },
                {
                    key: 'title',
                    render: (p: RepeatCheckoutProduct['details']) => {
                        return getProductTranslation(p, selectedLanguage).title;
                    },
                },
                {
                    key: 'price',
                    render: (p) => {
                        const price = getProductPrice(
                            {
                                details: p,
                            } as RepeatCheckoutProduct,
                            currency
                        );

                        return '+ ' + displayCurrencyPrice(price, currency);
                    },
                },
                {
                    key: 'action',
                    align: 'right',
                    render: (p) => (
                        <Button
                            type={onAddonSelected ? 'primary' : 'dashed'}
                            size="small"
                            onClick={() => {
                                if (onAddonRemoved) {
                                    onAddonRemoved(p);
                                }

                                if (onAddonSelected) {
                                    onAddonSelected(p);
                                }
                            }}
                        >
                            {onAddonSelected
                                ? '+ ' + translate('add')
                                : translate('remove')}
                        </Button>
                    ),
                },
            ]}
            pagination={false}
        ></Table>
    );
}
