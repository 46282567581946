import { useState } from 'react';
import Product from '../../interfaces/product';
import SubscriptionCronSettings from '../SubscriptionCronSettings/SubscriptionCronSettings';
import { CronConfig } from '../../interfaces/subscription';
import { Button, Input, Select, Space, Switch, message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';

type Props = {
    product: Product;
};

const getLink = (
    product: Product,
    count: number,
    intervalType: 'MONTH' | 'YEAR' | 'WEEK',
    language: string,
    currency: string,
    price?: number | false
) => {
    let base = `/repeat_checkout/${product.shop_uuid}/`;
    base =
        base +
        `?products.0.uuid=${product.uuid}&products.0.quantity=1&currency=${currency}&language=${language}`;

    if (product.type === 'SUBSCRIPTION')
        base = base + `&interval_type=${intervalType}&interval_count=${count}`;

    if (price) {
        const salt = 'customPriceSalt';
        const combinedValue = `${price}${salt}`;
        const encoded = window.btoa(combinedValue);
        base = base + `&products.0.cp=${encoded}`;
    }

    return window.top?.location.origin + base;
};

export default function CreatePaymentLink({ product }: Props) {
    console.log(product);

    const [price, setPrice] = useState<number | false | undefined>();
    const [frequencyCount, setFrequencyCount] = useState<number>(1);
    const [frequencyType, setFrequencyType] = useState<
        'MONTH' | 'YEAR' | 'WEEK'
    >('MONTH');
    const [paymentLink, setPaymentLink] = useState<string>();
    const [giveCustomPrice, setGiveCustomPrice] = useState(false);
    const [currency, setCurrency] = useState(
        product.prices ? product.prices[0].currency.currency_code : 'ISK'
    );
    const [language, setLanguage] = useState(
        product.prices && product.prices[0].currency.currency_code === 'ISK'
            ? 'is'
            : 'en'
    );

    return (
        <div>
            {!!!paymentLink && (
                <div>
                    {product.type === 'SUBSCRIPTION' && (
                        <>
                            <h4>Veldu tíðni</h4>

                            <SubscriptionCronSettings
                                yearly={true}
                                monthly={true}
                                weekly={true}
                                initalFrequency={1}
                                initalFrequencyType="MONTH"
                                onChange={(config: CronConfig) => {
                                    setFrequencyCount(config.interval);
                                    setFrequencyType(config.interval_type);
                                }}
                                showTitle={false}
                                inputSize="large"
                            ></SubscriptionCronSettings>
                        </>
                    )}

                    <br />

                    <h4>Tungumál</h4>
                    <Select
                        style={{
                            width: '100%',
                        }}
                        defaultValue={language}
                        size="large"
                        onChange={(val) => {
                            setLanguage(val);
                        }}
                    >
                        <Select.Option value="is">Íslenska</Select.Option>
                        <Select.Option value="en">English</Select.Option>
                    </Select>

                    <br />
                    <br />

                    <h4>Sérverð</h4>
                    <Switch
                        onChange={(val) => {
                            setGiveCustomPrice(val);

                            if (!val) {
                                setPrice(false);
                            }
                        }}
                        checked={giveCustomPrice}
                    ></Switch>

                    {giveCustomPrice && (
                        <>
                            <br />
                            <br />
                            <h4>Veldu verð</h4>

                            <Input
                                defaultValue={
                                    product.price.base_price || undefined
                                }
                                placeholder="Verð"
                                size="large"
                                onChange={(e) => {
                                    setPrice(parseInt(e.target.value));
                                }}
                            ></Input>
                        </>
                    )}

                    <br />
                    <br />

                    <h4>Gjaldmiðill</h4>

                    <Select
                        style={{
                            width: 75,
                        }}
                        defaultValue={
                            product.prices
                                ? product.prices[0].currency.currency_code
                                : 'ISK'
                        }
                        size="large"
                        onChange={(val) => {
                            setCurrency(val);
                        }}
                    >
                        {product.prices ? (
                            product.prices.map((price) => {
                                return (
                                    <Select.Option
                                        value={price.currency.currency_code}
                                    >
                                        {price.currency.currency_code}
                                    </Select.Option>
                                );
                            })
                        ) : (
                            <>
                                <Select.Option value="ISK">ISK</Select.Option>
                                <Select.Option value="EUR">EUR</Select.Option>
                                <Select.Option value="USD">USD</Select.Option>
                            </>
                        )}
                    </Select>

                    <br />
                    <br />

                    <Button
                        onClick={() => {
                            if (!frequencyCount || !frequencyType) {
                                message.error(
                                    'Vinsamlegast fylltu út alla reiti'
                                );
                                return;
                            }

                            const link = getLink(
                                product,
                                frequencyCount,
                                frequencyType,
                                language,
                                currency,
                                price
                            );

                            setPaymentLink(link);
                        }}
                        size="large"
                        block
                        type="primary"
                    >
                        {' '}
                        Fá hlekk á sölusíðu
                    </Button>
                </div>
            )}

            {paymentLink && (
                <div style={{ textAlign: 'center' }}>
                    <Space
                        style={{ display: 'flex', flex: 1 }}
                        direction="vertical"
                    >
                        <Input
                            size="large"
                            disabled
                            value={paymentLink}
                        ></Input>

                        {/* @ts-ignore */}
                        <CopyToClipboard
                            text={paymentLink}
                            onCopy={() =>
                                message.success(
                                    'Tókst, hlekkurinn hefur verið afritaður!'
                                )
                            }
                        >
                            <Button size="large" block>
                                <CopyOutlined /> Afrita
                            </Button>
                        </CopyToClipboard>

                        <div style={{ fontSize: 12, color: 'lightgray' }}>
                            - - eða - -
                        </div>

                        <Button
                            href={paymentLink}
                            size="large"
                            block
                            target="_blank"
                        >
                            <LinkOutlined /> Opna í nýjum glugga
                        </Button>
                    </Space>
                </div>
            )}
        </div>
    );
}
