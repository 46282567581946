// @ts-nocheck
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    message,
    Input,
    Upload,
    Form,
    Alert,
    Tabs,
    Progress,
} from 'antd';
import getVideoId from 'get-video-id';
import * as tus from 'tus-js-client';
import UploadRepeatMedia from './UploadRepeatMedia';

type Props = {
    success: Function;
    onError: Function;
    shopUUID: string;
    shopID: number;
};

const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const CHUNK_SIZE = 1048576 * 5; // 5MB

const splitFileIntoChunks = (file: File, chunkSize: number): Blob[] => {
    const chunks: Blob[] = [];
    let offset = 0;

    while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
    }

    return chunks;
};

const MAX_RETRIES = 3; // Maximum retry attempts
const RETRY_DELAY = 2000; // Initial delay in milliseconds (2 seconds)

async function uploadChunk(
    uploadUrl: string,
    chunk: Blob,
    chunkIndex: number,
    file: File,
    token: string
) {
    const start = chunkIndex * CHUNK_SIZE;
    const end = start + chunk.size - 1;
    const contentRange = `bytes ${start}-${end}/${file.size}`;

    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
        try {
            const response = await axios.put(uploadUrl, chunk, {
                headers: {
                    'Content-Range': contentRange,
                    'Content-Type': 'application/octet-stream',
                    Authorization: 'Bearer ' + token,
                },
                maxRedirects: 0,
                validateStatus: (status) => status === 200 || status === 308,
            });

            if (response.status === 308 && response.headers.location) {
                // Redirect handling
                return { nextUrl: response.headers.location };
            }

            if (response.status === 200) {
                // Successful chunk upload
                return response.data;
            }
        } catch (error) {
            console.warn(
                `Chunk ${chunkIndex} upload failed (Attempt ${attempt} of ${MAX_RETRIES}).`,
                error.message
            );

            if (attempt === MAX_RETRIES) {
                console.error(
                    `Chunk ${chunkIndex} failed after ${MAX_RETRIES} attempts.`
                );
                throw error; // Give up after max retries
            }

            // Exponential backoff delay
            await new Promise((resolve) =>
                setTimeout(resolve, RETRY_DELAY * 2 ** (attempt - 1))
            );
        }
    }
}

async function initiateUploadSession(file) {
    const title = form.getFieldValue('title') || '';

    try {
        const response = await axios.post('/api/v1/videos/upload-resumable', {
            name: file.name,
            size: file.size,
            type: file.type,
            videoPrivacy: 'private', // Adjust as needed
            channelId: '<your-channel-id>', // Replace with your channel ID
        });
        return response.data.upload_url; // Return the upload URL
    } catch (error) {
        console.error('Failed to initiate upload session:', error);
        throw error;
    }
}

export default function UploadContentAsset(props: Props) {
    const [form] = Form.useForm();
    const [forceRerender, setForceRerender] = useState(true);
    const [vimeoFolderId, setVimeoFolderId] = useState<string>();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        axios.get(`/verslun/api/shop/${props.shopUUID}/`).then((rsp) => {
            setVimeoFolderId(rsp.data.vimeo_folder_id);
        });
    }, []);

    return (
        <div>
            <Form
                form={form}
                onValuesChange={() => {
                    setForceRerender(!forceRerender);
                }}
                onFinish={(values) => {
                    const serviceInfo: {
                        id: string | null;
                        service: string | null;
                    } = getVideoId(values.youtubeVimeo);

                    if (serviceInfo.service && serviceInfo.id) {
                        let params: {
                            youtube_uri?: string;
                            vimeo_uri?: string;
                            title: string;
                            type?: string;
                        } = {
                            title: values.title,
                        };

                        if (serviceInfo.service === 'youtube') {
                            params.youtube_uri =
                                'https://www.youtube.com/watch?v=' +
                                serviceInfo.id;
                            params.type = 'CUSTOM_YOUTUBE';
                        }
                        if (serviceInfo.service === 'vimeo') {
                            params.vimeo_uri =
                                'https://vimeo.com/' + serviceInfo.id;
                            params.type = 'CUSTOM_VIMEO';
                        }

                        axios.post('/efni/api/asset/', params).then((rsp) => {
                            props.success(rsp);
                        });
                    } else {
                        message.error('Þetta er ekki gildur hlekkur');
                    }
                }}
                layout="vertical"
            >
                <Form.Item
                    rules={[
                        { required: true, message: 'Þú verður að hafa titil' },
                        {
                            min: 3,
                            message: 'Titill verður að vera a.m.k. 3 stafir',
                        },
                    ]}
                    name="title"
                    label="Titill"
                >
                    <Input size="large"></Input>
                </Form.Item>

                <Tabs
                    onChange={(e) => {
                        setSubmitDisabled(true);
                    }}
                    defaultActiveKey="upload-repeat-media-center"
                    items={[
                        {
                            label: 'Læst myndband',
                            key: 'upload-repeat-media-center',
                            children: (
                                <>
                                    {!!!form.getFieldValue('title') && (
                                        <Alert
                                            className="mb20"
                                            style={{ textAlign: 'center' }}
                                            type="warning"
                                            description="Þú verður að velja titil ÁÐUR en þú hleður skránni upp."
                                        ></Alert>
                                    )}

                                    {!!form.getFieldValue('title') &&
                                        form.getFieldValue('title').length >
                                            2 && (
                                            <>
                                                <Form.Item
                                                    label="Skjal"
                                                    valuePropName="fileList"
                                                    getValueFromEvent={normFile}
                                                >
                                                    <Upload.Dragger
                                                        multiple={false}
                                                        showUploadList={false}
                                                        beforeUpload={(
                                                            file
                                                        ) => {
                                                            console.log(
                                                                'FILE',
                                                                file
                                                            );
                                                            const isOk =
                                                                /(video|x-(?:[0-9A-Za-z!#$%&'*+.^_`|~-]+))\/([0-9A-Za-z!#$%&'*+.^_`|~-]+)/g;
                                                            const t = isOk.test(
                                                                file.type
                                                            );

                                                            if (!isOk) {
                                                                message.error(
                                                                    `${file.name} er ekki leyfileg tegund skráar.`
                                                                );
                                                            }

                                                            return (
                                                                t ||
                                                                Upload.LIST_IGNORE
                                                            );
                                                        }}
                                                        customRequest={({
                                                            file,
                                                            onSuccess,
                                                            onError,
                                                            onProgress,
                                                        }) => {
                                                            setUploadProgress(
                                                                1
                                                            );
                                                            const title =
                                                                form.getFieldValue(
                                                                    'title'
                                                                ) || '';
                                                            return axios
                                                                .post(
                                                                    '/efni/api/get_repeat_media_upload_url',
                                                                    {
                                                                        // @ts-ignore
                                                                        file_size:
                                                                            file.size,
                                                                        title,
                                                                        filename:
                                                                            file.name,
                                                                        content_type:
                                                                            file.type,
                                                                        shop_uuid:
                                                                            props.shopUUID,
                                                                    }
                                                                )
                                                                .then((rsp) => {
                                                                    let uploadInitUrl =
                                                                        rsp.data
                                                                            .upload_url;

                                                                    uploadInitUrl =
                                                                        'https:' +
                                                                        uploadInitUrl;

                                                                    const token =
                                                                        rsp.data
                                                                            .token;

                                                                    axios
                                                                        .post(
                                                                            uploadInitUrl,
                                                                            {
                                                                                name: file.name,
                                                                                size: file.size,
                                                                                mimeType:
                                                                                    file.type,
                                                                                filename:
                                                                                    file.name,
                                                                                channelId:
                                                                                    rsp
                                                                                        .data
                                                                                        .channel_id,
                                                                            },
                                                                            {
                                                                                headers:
                                                                                    {
                                                                                        Authorization:
                                                                                            'Bearer ' +
                                                                                            token,
                                                                                        filename:
                                                                                            encodeURIComponent(
                                                                                                file.name
                                                                                            ),
                                                                                        'X-Upload-Content-Length':
                                                                                            file.size,
                                                                                        'X-Upload-Content-Type':
                                                                                            file.type,
                                                                                    },
                                                                            }
                                                                        )
                                                                        .then(
                                                                            (
                                                                                res
                                                                            ) => {
                                                                                let uploadUrl =
                                                                                    'https:' +
                                                                                    res
                                                                                        .headers
                                                                                        .location;

                                                                                const chunks =
                                                                                    splitFileIntoChunks(
                                                                                        file,
                                                                                        CHUNK_SIZE
                                                                                    );

                                                                                const uploadChunks =
                                                                                    async () => {
                                                                                        for (
                                                                                            let i = 0;
                                                                                            i <
                                                                                            chunks.length;
                                                                                            i++
                                                                                        ) {
                                                                                            const rsp =
                                                                                                await uploadChunk(
                                                                                                    uploadUrl,
                                                                                                    chunks[
                                                                                                        i
                                                                                                    ],
                                                                                                    i,
                                                                                                    file,
                                                                                                    token
                                                                                                );

                                                                                            const percentCompleted =
                                                                                                Math.round(
                                                                                                    ((i +
                                                                                                        1) /
                                                                                                        chunks.length) *
                                                                                                        100
                                                                                                );
                                                                                            setUploadProgress(
                                                                                                percentCompleted
                                                                                            );
                                                                                            if (
                                                                                                rsp &&
                                                                                                rsp.video
                                                                                            ) {
                                                                                                axios
                                                                                                    .post(
                                                                                                        '/efni/api/asset/',
                                                                                                        {
                                                                                                            title,
                                                                                                            type: 'REPEAT_MEDIA',
                                                                                                            repeat_media_asset:
                                                                                                                {
                                                                                                                    asset_id:
                                                                                                                        rsp
                                                                                                                            .video
                                                                                                                            .id,
                                                                                                                    short_uuid:
                                                                                                                        rsp
                                                                                                                            .video
                                                                                                                            .shortUUID,
                                                                                                                    uuid: rsp
                                                                                                                        .video
                                                                                                                        .uuid,
                                                                                                                    shop: props.shopID,
                                                                                                                },
                                                                                                            shop: props.shopUUID,
                                                                                                        }
                                                                                                    )
                                                                                                    .then(
                                                                                                        (
                                                                                                            rsp
                                                                                                        ) => {
                                                                                                            props.success(
                                                                                                                rsp
                                                                                                            );
                                                                                                        }
                                                                                                    )
                                                                                                    .finally(
                                                                                                        () => {
                                                                                                            setUploadProgress(
                                                                                                                0
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                            }
                                                                                        }
                                                                                    };

                                                                                uploadChunks();
                                                                            }
                                                                        );
                                                                });
                                                        }}
                                                    >
                                                        <>
                                                            <h2>Smelltu hér</h2>

                                                            <h4>
                                                                eða dragðu skjal
                                                                hingað
                                                            </h4>
                                                        </>
                                                    </Upload.Dragger>
                                                    <Progress
                                                        percent={Math.ceil(
                                                            uploadProgress
                                                        )}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}
                                </>
                            ),
                        },
                        {
                            label: 'Skrá (Mynd/Hljóð/PDF)',
                            key: 'upload-file',
                            children: (
                                <>
                                    {!!!form.getFieldValue('title') && (
                                        <Alert
                                            className="mb20"
                                            style={{ textAlign: 'center' }}
                                            type="warning"
                                            description="Þú verður að velja titil ÁÐUR en þú hleður skránni upp."
                                        ></Alert>
                                    )}

                                    {!!form.getFieldValue('title') && (
                                        <Form.Item
                                            name="asset"
                                            label="Skjal"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                        >
                                            <Upload.Dragger
                                                multiple={false}
                                                beforeUpload={(file) => {
                                                    console.log(
                                                        'File type:',
                                                        file.type
                                                    ); // Add this to debug
                                                    const isOk =
                                                        /(audio|image|video|application\/pdf|x-(?:[0-9A-Za-z!#$%&'*+.^_`|~-]+))\/([0-9A-Za-z!#$%&'*+.^_`|~-]+)|application\/pdf/g;
                                                    const t = isOk.test(
                                                        file.type
                                                    );
                                                    if (!isOk) {
                                                        message.error(
                                                            `${file.name} er ekki leyfileg tegund skráar.`
                                                        );
                                                    }

                                                    console.log('ttt', t);

                                                    return (
                                                        t || Upload.LIST_IGNORE
                                                    );
                                                }}
                                                customRequest={({
                                                    file,
                                                    onSuccess,
                                                    onError,
                                                }) => {
                                                    const title =
                                                        form.getFieldValue(
                                                            'title'
                                                        ) || '';

                                                    let uniqueId =
                                                        Date.now().toString(
                                                            36
                                                        ) +
                                                        Math.random()
                                                            .toString(36)
                                                            .substring(2)
                                                            .toString();

                                                    //Adding file extension to the uniqueId if it exists
                                                    if (
                                                        file.name.includes('.')
                                                    ) {
                                                        uniqueId +=
                                                            file.name.substring(
                                                                file.name.lastIndexOf(
                                                                    '.'
                                                                )
                                                            );
                                                    }

                                                    axios
                                                        .post(
                                                            `/efni/api/get_temporary_do_token/${props.shopUUID}/`,
                                                            {
                                                                file_name:
                                                                    uniqueId,
                                                            }
                                                        )
                                                        .then((rsp) => {
                                                            const token =
                                                                rsp.data.token;
                                                            // Construct a FormData object with the required fields
                                                            const formData =
                                                                new FormData();
                                                            formData.append(
                                                                'key',
                                                                token.fields.key
                                                            );
                                                            formData.append(
                                                                'x-amz-credential',
                                                                token.fields[
                                                                    'x-amz-credential'
                                                                ]
                                                            );

                                                            formData.append(
                                                                'x-amz-date',
                                                                token.fields[
                                                                    'x-amz-date'
                                                                ]
                                                            );

                                                            formData.append(
                                                                'ACL',
                                                                'public-read'
                                                            );

                                                            formData.append(
                                                                'x-amz-algorithm',
                                                                token.fields[
                                                                    'x-amz-algorithm'
                                                                ]
                                                            );
                                                            formData.append(
                                                                'policy',
                                                                token.fields
                                                                    .policy
                                                            );
                                                            formData.append(
                                                                'x-amz-signature',
                                                                token.fields[
                                                                    'x-amz-signature'
                                                                ]
                                                            );
                                                            formData.append(
                                                                'x-amz-acl',
                                                                token.fields[
                                                                    'x-amz-acl'
                                                                ]
                                                            );

                                                            formData.append(
                                                                'file',
                                                                file
                                                            );

                                                            // Make a POST request to the DigitalOcean Space URL
                                                            const response =
                                                                axios
                                                                    .post(
                                                                        'https://repeat.ams3.digitaloceanspaces.com/',
                                                                        formData,
                                                                        {
                                                                            headers:
                                                                                {
                                                                                    'Content-Type':
                                                                                        'multipart/form-data',
                                                                                },
                                                                            onUploadProgress:
                                                                                function (
                                                                                    progressEvent
                                                                                ) {
                                                                                    let percentCompleted =
                                                                                        Math.round(
                                                                                            (progressEvent.loaded *
                                                                                                100) /
                                                                                                (progressEvent.total ||
                                                                                                    1)
                                                                                        );

                                                                                    setUploadProgress(
                                                                                        percentCompleted
                                                                                    );
                                                                                },
                                                                        }
                                                                    )
                                                                    .then(
                                                                        (
                                                                            rsp
                                                                        ) => {
                                                                            const url =
                                                                                token.url +
                                                                                token
                                                                                    .fields
                                                                                    .key;
                                                                            //Checking if the file is a sound file
                                                                            const audioRegex =
                                                                                /^audio\//;
                                                                            const pdfRegex =
                                                                                /^application\/pdf/;
                                                                            const imageRegex =
                                                                                /^image\//;

                                                                            // Check if it's an audio file
                                                                            const isAudio =
                                                                                audioRegex.test(
                                                                                    file.type
                                                                                );

                                                                            // Check if it's a PDF
                                                                            const isPDF =
                                                                                pdfRegex.test(
                                                                                    file.type
                                                                                );

                                                                            // Check if it's an image
                                                                            const isImage =
                                                                                imageRegex.test(
                                                                                    file.type
                                                                                );

                                                                            let assetType;

                                                                            if (
                                                                                isImage
                                                                            ) {
                                                                                assetType =
                                                                                    'IMAGE';
                                                                            } else if (
                                                                                isAudio
                                                                            ) {
                                                                                assetType =
                                                                                    'AUDIO';
                                                                            } else if (
                                                                                isPDF
                                                                            ) {
                                                                                assetType =
                                                                                    'PDF';
                                                                            }

                                                                            if (
                                                                                assetType
                                                                            ) {
                                                                                axios
                                                                                    .post(
                                                                                        '/efni/api/asset/',
                                                                                        {
                                                                                            title,
                                                                                            type: assetType,
                                                                                            file_uri:
                                                                                                url,
                                                                                            size_in_bytes:
                                                                                                file.size,
                                                                                        }
                                                                                    )
                                                                                    .then(
                                                                                        (
                                                                                            rsp
                                                                                        ) => {
                                                                                            props.success(
                                                                                                rsp
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .finally(
                                                                                        () => {
                                                                                            setUploadProgress(
                                                                                                0
                                                                                            );
                                                                                        }
                                                                                    );
                                                                            } else {
                                                                                console.log(
                                                                                    'nope'
                                                                                );
                                                                            }
                                                                        }
                                                                    )
                                                                    .catch(
                                                                        (
                                                                            error
                                                                        ) =>
                                                                            console.error(
                                                                                'Error uploading file:',
                                                                                error
                                                                            )
                                                                    );
                                                        });

                                                    // // @ts-ignore

                                                    // var formData =
                                                    //     new FormData();

                                                    // let fileType;
                                                    // // @ts-ignore
                                                    // if (
                                                    //     (
                                                    //         file.type || ''
                                                    //     ).includes('image')
                                                    // ) {
                                                    //     fileType = 'IMAGE';
                                                    //     formData.append(
                                                    //         'image',
                                                    //         file
                                                    //     );
                                                    // }

                                                    // // @ts-ignore
                                                    // if (
                                                    //     (
                                                    //         file.type || ''
                                                    //     ).includes('video')
                                                    // ) {
                                                    //     fileType = 'VIMEO';
                                                    //     formData.append(
                                                    //         'video_file',
                                                    //         file
                                                    //     );
                                                    // }

                                                    // // @ts-ignore
                                                    // if (
                                                    //     (
                                                    //         file.type || ''
                                                    //     ).includes('audio')
                                                    // ) {
                                                    //     fileType = 'AUDIO';
                                                    //     formData.append(
                                                    //         'audio_file',
                                                    //         file
                                                    //     );
                                                    // }

                                                    // formData.append(
                                                    //     'title',
                                                    //     title
                                                    // );
                                                    // formData.append(
                                                    //     'type',
                                                    //     fileType || ''
                                                    // );

                                                    // if (
                                                    //     vimeoFolderId &&
                                                    //     fileType === 'VIDEO'
                                                    // )
                                                    //     formData.append(
                                                    //         'vimeo_folder_id',
                                                    //         vimeoFolderId
                                                    //     );

                                                    // axios.post(
                                                    //     '/upload_asset/',
                                                    //     formData,
                                                    //     {
                                                    //         headers: {
                                                    //             'Content-Type':
                                                    //                 'multipart/form-data',
                                                    //         },
                                                    //     }
                                                    // ).then((rsp) => {
                                                    //     // @ts-ignore
                                                    //     if (onSuccess)
                                                    //         onSuccess(
                                                    //             rsp.data,
                                                    //             file
                                                    //         );
                                                    //     props.success(rsp.data);
                                                    // });
                                                }}
                                            >
                                                <>
                                                    <h2>Smelltu hér</h2>

                                                    <h4>
                                                        eða dragðu skjal hingað
                                                    </h4>
                                                </>
                                            </Upload.Dragger>
                                            <Progress
                                                percent={Math.ceil(
                                                    uploadProgress
                                                )}
                                            />
                                        </Form.Item>
                                    )}
                                </>
                            ),
                        },
                        {
                            label: 'Youtube/Vimeo slóð',
                            key: 'youtube_vimeo',
                            children: (
                                <>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Þú verður að hafa hlekk',
                                            },
                                        ]}
                                        name="youtubeVimeo"
                                        label="Youtube eða Vimeo slóð"
                                    >
                                        <Input
                                            onChange={(e) =>
                                                setSubmitDisabled(
                                                    e.target.value
                                                        ? false
                                                        : true
                                                )
                                            }
                                            size="large"
                                        />
                                    </Form.Item>
                                </>
                            ),
                        },
                    ]}
                />

                <Button
                    disabled={submitDisabled}
                    htmlType="submit"
                    type="primary"
                    block
                    size="large"
                >
                    Staðfesta
                </Button>
            </Form>

            {/* <div>
                <AudioPlayer src='https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3' />
            </div> */}
        </div>
    );
}
