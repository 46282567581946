import { ReactElement, useState } from 'react';
import { Modal, Button, message } from 'antd';
import CardToken from '../../interfaces/cardToken';
import { SimpleCustomer } from '../../interfaces/customer';
import { Currency } from '../../interfaces/product';
import translate, { LanguageKey } from '../../translate/translate';
import UpdatePublicCreditCard from './UpdatePublicCreditCard';

interface Props {
    open: boolean;
    onCancel: () => void;
    customer: SimpleCustomer;
    className?: string;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}

export default function UpdateCreditCardModal({
    customer,
    onCancel,
    open,
    className,
    currencyCode,
    language,
}: Props): ReactElement {
    return (
        <Modal
            title={translate('connected_card', language)}
            footer={false}
            open={open}
            onCancel={() => {
                onCancel();
            }}
            className={className || ''}
        >
            <h4 className="capitalize">{customer.name}</h4>

            <UpdatePublicCreditCard
                customer={customer}
                currencyCode={currencyCode}
                language={language}
                onSuccess={onCancel}
            ></UpdatePublicCreditCard>
        </Modal>
    );
}
