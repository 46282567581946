import { useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import {
    Card,
    Table,
    Button,
    Badge,
    Input,
    Modal,
    Space,
    Form,
    InputNumber,
    message,
    Alert,
} from 'antd';
import {
    CalendarOutlined,
    EnvironmentOutlined,
    EditOutlined,
    CloseOutlined,
    WarningFilled,
    PlayCircleFilled,
    PushpinOutlined,
} from '@ant-design/icons';

import Subscription, {
    SubscriptionBundle,
} from '../../interfaces/subscription';
import { displayCurrencyPrice, getCronLabelSimple } from '../../utils';
import SubscriptionDeliveryOptionModal from '../SubscriptionOverview/SubscriptionDeliveryOptionModal/SubscriptionDeliveryOptionModal';
import { DeliveryOption } from '../../interfaces/delivery';
import CancelSubscriptionsPublic from './CancelSubscriptionsPublic';
import ReactivateSubscription from '../../components/PublicComponents/ReactivateSubscription/ReactivateSubscription';
import languages, { LanguageKey } from '../../translate/translate';
import ChangeBundleInterval from './ChangeBundleInterval';
import translate from '../../translate/translate';

type Props = {
    bundle: SubscriptionBundle;
    disabled: boolean;
    editable?: boolean;
    bordered?: boolean;
    onBundleChange?: () => void;
    language?: LanguageKey;
};

export default function SubscriptionBundleCard({
    bundle,
    disabled,
    editable = true,
    bordered = true,
    onBundleChange,
    language = 'is',
}: Props) {
    const subscriptionBundle = bundle;
    const [cancelSubscriptionsModal, setCancelSubscriptionsModal] = useState<
        string[] | false
    >();
    const [chooseDeliveryOptionModal, setChooseDeliveryOptionModal] = useState<
        false | SubscriptionBundle
    >(false);

    const [reactivateSubscriptionModal, setReactivateSubscriptionModal] =
        useState<Subscription | false>(false);
    const [
        changeSubscriptionQuantityModal,
        setChangeSubscriptionQuantityModal,
    ] = useState<false | Subscription>(false);

    const [changeBundleModal, setChangeBundleModal] = useState<
        SubscriptionBundle | false
    >(false);
    const [changeIntervalModal, setChangeIntervalModal] = useState<
        SubscriptionBundle | false
    >(false);

    const { subscriptions, interval, intervalType, date } = subscriptionBundle;
    let cronLabel = getCronLabelSimple(
        interval,
        intervalType as any,
        null,
        null,
        language
    );
    const withSelectedDeliveryOption = subscriptions.find(
        (s) => s.subscription_product.delivery_option
    );
    const withDeliveryConfig = subscriptions.find(
        (s) => s.product.delivery_config
    );
    const d = dayjs(date);

    return (
        <>
            {subscriptions.length > 0 && (
                <Card
                    className={`subscriptionOverviewCard ${
                        disabled || !editable ? 'inactiveSubscription' : ''
                    } ${!bordered ? 'noBorder' : ''}`}
                    bodyStyle={{
                        padding: 0,
                    }}
                    size="small"
                    title={
                        <div>
                            <div className="cronLabel">
                                <div>
                                    <strong
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        <CalendarOutlined />{' '}
                                        {cronLabel ? cronLabel + ' - ' : ''}
                                    </strong>{' '}
                                    <span className="cronLabelDate">
                                        {cronLabel
                                            ? languages('from', language)
                                            : languages('created', language)}
                                        : {d.format('D. MMM YYYY')}
                                    </span>
                                </div>
                            </div>

                            {!disabled && (
                                <div className="nextDate">
                                    {subscriptionBundle.upcomingDates &&
                                        subscriptionBundle.upcomingDates
                                            .length > 0 && (
                                            <>
                                                {languages('next', language)}:{' '}
                                                {subscriptionBundle.upcomingDates[0].format(
                                                    'D. MMM YYYY'
                                                )}
                                            </>
                                        )}
                                </div>
                            )}
                        </div>
                    }
                >
                    <Table
                        size="small"
                        style={{
                            margin: '5px 20px 0 20px',
                        }}
                        showHeader={false}
                        dataSource={subscriptions}
                        pagination={false}
                        rowKey={(subscription) => subscription.uuid}
                        columns={[
                            {
                                title: 'Áskrift',
                                render: (subscription: Subscription) => (
                                    <>
                                        <div>
                                            {!subscription.active && (
                                                <Badge status="error" />
                                            )}
                                            {subscription.resign_date &&
                                                subscription.active && (
                                                    <Badge status="warning" />
                                                )}
                                            {!subscription.resign_date &&
                                                subscription.active && (
                                                    <Badge status="success" />
                                                )}{' '}
                                            <span
                                                style={{
                                                    color: 'lightgray',
                                                }}
                                            >
                                                <Input
                                                    onClick={() => {
                                                        if (
                                                            disabled ||
                                                            !editable
                                                        )
                                                            return;
                                                        setChangeSubscriptionQuantityModal(
                                                            subscription
                                                        );
                                                    }}
                                                    disabled={
                                                        disabled || !editable
                                                    }
                                                    size="small"
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 50,
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    value={
                                                        subscription
                                                            .subscription_product
                                                            .quantity
                                                    }
                                                ></Input>{' '}
                                                x{' '}
                                            </span>
                                            <span>
                                                <span>
                                                    {subscription.product.title}{' '}
                                                    <span
                                                        style={{
                                                            color: 'lightgray',
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {' '}
                                                        (
                                                        {displayCurrencyPrice(
                                                            subscription
                                                                .subscription_product
                                                                .price,
                                                            subscription
                                                                .subscription_product
                                                                .currency_code
                                                        )}
                                                        )
                                                    </span>
                                                </span>

                                                <span>
                                                    {subscription.resign_date &&
                                                        subscription.active && (
                                                            <Alert
                                                                style={{
                                                                    marginTop: 5,
                                                                    fontSize: 12,
                                                                    padding:
                                                                        '5px 10px',
                                                                }}
                                                                message={
                                                                    <Space>
                                                                        {`${languages(
                                                                            'becomes_inactive',
                                                                            language
                                                                        )} ${dayjs(
                                                                            subscription.resign_date
                                                                        ).format(
                                                                            'D. MMM YYYY'
                                                                        )}`}

                                                                        {bundle
                                                                            .customer
                                                                            .shop
                                                                            .allow_subscription_reactivation &&
                                                                            editable && (
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        setReactivateSubscriptionModal(
                                                                                            subscription
                                                                                        )
                                                                                    }
                                                                                    size="small"
                                                                                    style={{
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                >
                                                                                    {languages(
                                                                                        'reactivate',
                                                                                        language
                                                                                    )}
                                                                                </Button>
                                                                            )}
                                                                    </Space>
                                                                }
                                                                type="warning"
                                                            />
                                                        )}
                                                </span>
                                            </span>
                                        </div>

                                        {subscription.is_paused && (
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    color: 'gray',
                                                }}
                                            >
                                                <WarningFilled
                                                    style={{ color: '#FFD23F' }}
                                                />{' '}
                                                {languages(
                                                    'subscription_is_paused',
                                                    language
                                                )}{' '}
                                                {/* <Button
                                                    size="small"
                                                    type="dashed"
                                                >
                                                    <PlayCircleFilled></PlayCircleFilled>
                                                    {languages(
                                                        'unpause_subscription',
                                                        language
                                                    )}
                                                </Button> */}
                                            </div>
                                        )}

                                        {subscription.add_ons?.map((addOn) => {
                                            return (
                                                <div
                                                    key={addOn.id}
                                                    style={{
                                                        marginTop: 5,
                                                        color: 'gray',
                                                    }}
                                                >
                                                    <div>
                                                        {translate(
                                                            'add_on_product',
                                                            language
                                                        )}
                                                        :{' '}
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {(addOn.quantity ||
                                                                1) !== 1
                                                                ? addOn.quantity ||
                                                                  ' x 1'
                                                                : ''}{' '}
                                                            {
                                                                addOn.product
                                                                    .title
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ),
                            },

                            {
                                title: languages('price', language),
                                align: 'right',
                                render: (subscription: Subscription) => (
                                    <div>
                                        <div>
                                            <Space>
                                                {displayCurrencyPrice(
                                                    subscription
                                                        .subscription_product
                                                        .price *
                                                        (subscription
                                                            .subscription_product
                                                            .quantity || 1),
                                                    subscription
                                                        .subscription_product
                                                        .currency_code
                                                )}

                                                {!disabled &&
                                                    editable &&
                                                    bundle.customer.shop
                                                        .customers_can_cancel_subscription &&
                                                    subscriptions.length >
                                                        1 && (
                                                        <>
                                                            <div
                                                                className="deliveryOptionLabel deliveryOptionLabelDanger deliveryOptionCircle"
                                                                onClick={() => {
                                                                    if (
                                                                        subscription.resign_date &&
                                                                        subscription.active
                                                                    ) {
                                                                        message.warning(
                                                                            `${languages(
                                                                                'subscription_already_inactive',
                                                                                language
                                                                            )} ${dayjs(
                                                                                subscription.resign_date
                                                                            ).format(
                                                                                'D. MMM YYYY'
                                                                            )}`
                                                                        );
                                                                    } else {
                                                                        setCancelSubscriptionsModal(
                                                                            [
                                                                                subscription.uuid,
                                                                            ]
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <CloseOutlined />
                                                            </div>
                                                        </>
                                                    )}
                                            </Space>
                                        </div>

                                        <div>
                                            {subscription.add_ons?.map(
                                                (addOn) => {
                                                    return (
                                                        <div key={addOn.id}>
                                                            <div>
                                                                <Space>
                                                                    {displayCurrencyPrice(
                                                                        addOn.price,
                                                                        addOn.currency_code
                                                                    )}
                                                                </Space>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                ),
                            },

                            // {
                            //     title: <EditOutlined />,
                            //     align: 'right',
                            //     render: (subscription: Subscription) => (
                            //         <Switch
                            //             onClick={(e) => {
                            //
                            //             }}
                            //             disabled={disabled}
                            //             size="small"
                            //             checked={!disabled}
                            //         ></Switch>
                            //     ),
                            // },
                        ]}
                    ></Table>

                    {!disabled && editable && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '20px 20px 20px',
                            }}
                        >
                            <div
                                style={{
                                    color: 'gray',
                                    fontSize: 12,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (
                                        editable &&
                                        !disabled &&
                                        withDeliveryConfig
                                    ) {
                                        setChooseDeliveryOptionModal(
                                            subscriptionBundle
                                        );
                                    }
                                }}
                            >
                                {withSelectedDeliveryOption &&
                                    withDeliveryConfig && (
                                        <>
                                            <EnvironmentOutlined />{' '}
                                            <span>
                                                {
                                                    withSelectedDeliveryOption
                                                        .subscription_product
                                                        ?.delivery_option?.title
                                                }
                                            </span>
                                            <div>
                                                {withSelectedDeliveryOption
                                                    ?.subscription_product
                                                    ?.posturinn_location_name && (
                                                    <div
                                                        style={{
                                                            marginLeft: 15,
                                                            fontSize: 11,
                                                        }}
                                                    >
                                                        {
                                                            withSelectedDeliveryOption
                                                                ?.subscription_product
                                                                ?.posturinn_location_name
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                {!withSelectedDeliveryOption &&
                                    withDeliveryConfig && (
                                        <span>
                                            {languages(
                                                'shipping_method_missing',
                                                language
                                            )}
                                        </span>
                                    )}
                            </div>

                            <div>
                                {editable && (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setChangeBundleModal(bundle);
                                            }}
                                        >
                                            <EditOutlined />
                                            {languages('change', language)}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Card>
            )}

            <Modal
                open={!!cancelSubscriptionsModal}
                title={languages('select_subscriptions_to_cancel', language)}
                footer={null}
                onCancel={() => {
                    setCancelSubscriptionsModal(false);
                }}
                className="editCustomerPublicModal"
            >
                {cancelSubscriptionsModal && (
                    <CancelSubscriptionsPublic
                        language={language}
                        subscriptionBundle={subscriptionBundle}
                        selectedSubscriptions={cancelSubscriptionsModal}
                        onClose={() => {
                            // rsp.data.changes = {
                            //     "uuid": subscription.uuid,
                            //     "resign_date": subscription.resign_date,
                            //     "active": subscription.active,
                            // }

                            if (onBundleChange) onBundleChange();

                            setCancelSubscriptionsModal(false);
                        }}
                    ></CancelSubscriptionsPublic>
                )}
            </Modal>

            {!!chooseDeliveryOptionModal &&
                chooseDeliveryOptionModal.subscriptions.find(
                    (s) => s.product.delivery_config
                ) && (
                    <SubscriptionDeliveryOptionModal
                        language={language}
                        open={!!chooseDeliveryOptionModal}
                        onCancel={() => {
                            setChooseDeliveryOptionModal(false);
                        }}
                        subscription={
                            chooseDeliveryOptionModal.subscriptions.find(
                                (s) => s.product.delivery_config
                            ) as Subscription
                        }
                        returnType="payload"
                        onSuccess={(payload: DeliveryOption) => {
                            axios
                                .post(
                                    '/askrift/api/public/change_delivery_option/',
                                    {
                                        delivery_config: payload,
                                        subscription_uuids:
                                            chooseDeliveryOptionModal.subscriptions.map(
                                                (s) => s.uuid
                                            ),
                                    }
                                )
                                .then((res) => {
                                    if (res.data.success) {
                                        setChooseDeliveryOptionModal(false);
                                        if (onBundleChange) onBundleChange();

                                        message.success(
                                            languages(
                                                'delivery_option_changed',
                                                language
                                            )
                                        );
                                    } else {
                                        message.error(
                                            languages(
                                                'delivery_option_change_error',
                                                language
                                            )
                                        );
                                    }
                                })
                                .catch(() => {
                                    message.error(
                                        languages(
                                            'delivery_option_change_error',
                                            language
                                        )
                                    );
                                });
                        }}
                    ></SubscriptionDeliveryOptionModal>
                )}

            {changeSubscriptionQuantityModal && (
                <Modal
                    open={!!changeSubscriptionQuantityModal}
                    title={
                        <>
                            {languages('change_quantity', language)} -{' '}
                            {changeSubscriptionQuantityModal.product.title}
                        </>
                    }
                    onCancel={() => {
                        setChangeSubscriptionQuantityModal(false);
                    }}
                    footer={false}
                >
                    <Form
                        layout="vertical"
                        size="large"
                        onFinish={(values) => {
                            if (
                                values.quantity ===
                                changeSubscriptionQuantityModal
                                    .subscription_product.quantity
                            ) {
                                setChangeSubscriptionQuantityModal(false);
                                return;
                            } else if (values.quantity > 0) {
                                axios
                                    .post(
                                        '/askrift/api/change_subscription_products_public/',
                                        {
                                            uuids: [
                                                changeSubscriptionQuantityModal.uuid,
                                            ],
                                            quantity: values.quantity,
                                        }
                                    )
                                    .then((rsp) => {
                                        if (rsp.data.success) {
                                            if (onBundleChange) {
                                                onBundleChange();
                                            }
                                            setChangeSubscriptionQuantityModal(
                                                false
                                            );
                                            message.success(
                                                languages(
                                                    'quantity_changed',
                                                    language
                                                )
                                            );
                                        }
                                    });
                            }
                        }}
                    >
                        <Form.Item
                            label={languages('quantity', language)}
                            name="quantity"
                            rules={[
                                {
                                    required: true,
                                    message: languages(
                                        'please_select_quantity',
                                        language
                                    ),
                                },
                                {
                                    type: 'number',
                                    min: 1,
                                    message: languages(
                                        'quantity_must_be_1',
                                        language
                                    ),
                                },
                            ]}
                        >
                            <InputNumber
                                defaultValue={
                                    changeSubscriptionQuantityModal
                                        .subscription_product.quantity || 1
                                }
                            ></InputNumber>
                        </Form.Item>

                        <Button
                            size="large"
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            {languages('confirm', language)}
                        </Button>
                    </Form>
                </Modal>
            )}

            {reactivateSubscriptionModal && (
                <ReactivateSubscription
                    subscription={reactivateSubscriptionModal}
                    onCancel={() => setReactivateSubscriptionModal(false)}
                    onSuccess={() => {
                        if (onBundleChange) onBundleChange();

                        setReactivateSubscriptionModal(false);
                    }}
                ></ReactivateSubscription>
            )}

            {!!changeIntervalModal && (
                <Modal
                    open={!!changeIntervalModal}
                    onCancel={() => setChangeIntervalModal(false)}
                    footer={null}
                    className="editCustomerPublicModal"
                >
                    <ChangeBundleInterval
                        language={language}
                        bundle={bundle}
                        onSuccess={(newCronConfig) => {
                            setChangeIntervalModal(false);

                            if (onBundleChange) onBundleChange();
                        }}
                    ></ChangeBundleInterval>
                </Modal>
            )}

            {changeBundleModal && (
                <Modal
                    title={languages('what_to_change', language)}
                    open={!!changeBundleModal}
                    footer={null}
                    onCancel={() => setChangeBundleModal(false)}
                >
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        {!!!bundle.customer.shop
                            .customers_can_cancel_subscription &&
                            !!!withDeliveryConfig && (
                                <Alert
                                    message={languages(
                                        'cant_change_subscription',
                                        language
                                    )}
                                    type="warning"
                                    showIcon
                                />
                            )}

                        {withDeliveryConfig && (
                            <Button
                                block
                                size="large"
                                onClick={() => {
                                    setChooseDeliveryOptionModal(
                                        changeBundleModal
                                    );
                                    setChangeBundleModal(false);
                                }}
                            >
                                <EnvironmentOutlined />{' '}
                                {languages('delivery_option', language)}
                            </Button>
                        )}

                        {bundle.subscriptions.every(
                            (s) => s.product.interval_editable
                        ) && (
                            <Button
                                block
                                size="large"
                                onClick={() => {
                                    setChangeIntervalModal(bundle);
                                    setChangeBundleModal(false);
                                }}
                            >
                                <EditOutlined />{' '}
                                {languages(
                                    'change_interval_frequency',
                                    language
                                )}
                            </Button>
                        )}

                        {bundle.customer.shop
                            .customers_can_cancel_subscription && (
                            <Button
                                danger
                                block
                                type="link"
                                onClick={() => {
                                    setCancelSubscriptionsModal([]);
                                    setChangeBundleModal(false);
                                }}
                            >
                                <CloseOutlined />{' '}
                                {languages('cancel_subscription', language)}
                            </Button>
                        )}
                    </Space>
                </Modal>
            )}
        </>
    );
}
