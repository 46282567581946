import { useEffect, useState } from 'react';
import Axios from 'axios';
import {
    Space,
    Form,
    Tabs,
    Button,
    Input,
    Select,
    message,
    InputNumber,
    Calendar,
    Switch,
    Table,
    Alert,
} from 'antd';

// Utility functions
import { createSingleSubscription } from '../../utils/subscriptionUtils';

/* Interfaces */
import Shop from '../../interfaces/shop';
import Customer from '../../interfaces/customer';
import Product, { IntervalCombination } from '../../interfaces/product';
import DeliverySelector from '../DeliverySelector/DeliverySelector';
import SubscriptionCronSettings from '../SubscriptionCronSettings/SubscriptionCronSettings';
import { DeliveryOption, DroppLocationRes } from '../../interfaces/delivery';
import dayjs from 'dayjs';
import translate from '../../translate/translate';
import { displayCurrencyPrice } from '../../utils';
import ProductSearchBox from '../ProductOverview/ProductSearchBox';

type Props = {
    customer: Customer;
    onSuccess?: Function;
};

export interface NewCronConfig {
    interval?: number;
    interval_type?: 'MONTH' | 'WEEK' | 'YEAR';
    base_date?: string;
    base_date_is_signup_date: false;
}

export default function CreateSubscription(props: Props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption>();
    const [newCronConfig, setNewCronConfig] = useState<NewCronConfig>();
    const [products, setProducts] = useState<Product[]>([]);
    const [shop, setShop] = useState<Shop | undefined>();
    const [price, setPrice] = useState<number>();
    const [selectedCurrency, setSelectedCurrency] = useState('ISK');
    const [selectedDroppLocation, setSelectedDroppLocation] =
        useState<DroppLocationRes>();

    useEffect(() => {
        Axios.get('/verslun/api/shop/' + props.customer.shop_uuid + '/').then(
            (rsp) => {
                setShop(rsp.data);
            }
        );

        Axios.get(
            '/verslun/api/product/?page_size=5&shop__uuid=' +
                props.customer.shop_uuid
        );
    }, []);

    return (
        <div
            className="roundedBorders"
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            {shop && (
                <Form
                    style={{ flex: 1 }}
                    size="large"
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        if (values.payment_method === 'FREE') {
                            values.payment_method = 'CARD';
                        }

                        if (!values.product) {
                            message.error(
                                'Þú verður að velja tegund áskriftar'
                            );
                            return;
                        }

                        if (
                            selectedProduct &&
                            selectedProduct.delivery_config &&
                            !selectedDeliveryOption
                        ) {
                            message.error('Þú verður að velja afhendingarmáta');
                            return;
                        } else if (!!selectedDeliveryOption) {
                            if (
                                (
                                    selectedDeliveryOption.applicable_postal_codes ||
                                    []
                                ).length > 0
                            ) {
                                if (
                                    !selectedDeliveryOption.applicable_postal_codes?.includes(
                                        props.customer.postal_code || ''
                                    )
                                ) {
                                    message.error(
                                        <div>
                                            <div>
                                                {translate(
                                                    'postal_code_not_accepted',
                                                    'is'
                                                )}
                                            </div>

                                            <div>
                                                {translate(
                                                    'applicable_postal_codes_are',
                                                    'is'
                                                )}
                                                :
                                                {(
                                                    selectedDeliveryOption.applicable_postal_codes ||
                                                    []
                                                )?.map((postalCode, key) => {
                                                    return (
                                                        <strong key={key}>
                                                            {key !== 0 && ','}{' '}
                                                            {postalCode}
                                                        </strong>
                                                    );
                                                })}
                                            </div>
                                        </div>,
                                        5
                                    );
                                    return;
                                }
                            }
                        }

                        if (
                            selectedProduct &&
                            selectedProduct.cron_transaction
                        ) {
                            if (!newCronConfig) {
                                message.error(
                                    'Þú verður að velja tíðni sendinga'
                                );
                                return;
                            } else {
                                if (
                                    !newCronConfig.interval ||
                                    !newCronConfig.interval_type
                                ) {
                                    message.error(
                                        'Þú verður að velja tíðni sendinga'
                                    );
                                    return;
                                }
                            }
                        }

                        setLoading(true);

                        const attributes = Object.keys(values).filter((value) =>
                            value.startsWith('attribute.')
                        );

                        let attributePayload = attributes.map((key) => {
                            return {
                                attribute_uuid: key.split('.')[1],
                                value: values[key],
                            };
                        }) as [] | undefined;

                        if (attributePayload?.length === 0) {
                            attributePayload = undefined;
                        }

                        const intervalPayload = newCronConfig
                            ? {
                                  count: newCronConfig.interval || 1,
                                  type: newCronConfig.interval_type || 'MONTH',
                                  base_date:
                                      newCronConfig.base_date ||
                                      dayjs().toISOString(),
                              }
                            : undefined;

                        const deliveryPayload = !!selectedDeliveryOption
                            ? {
                                  external_delivery_location_id:
                                      selectedDroppLocation?.id as
                                          | number
                                          | undefined,
                                  delivery_option:
                                      selectedDeliveryOption?.id as
                                          | number
                                          | undefined,
                                  delivery_postal_code:
                                      (values.posturinn_dpo_postal_code ||
                                          values.posturinn_dpo_postal_code) as
                                          | string
                                          | undefined,
                                  posturinn_location_name:
                                      (values.posturinn_dpt_name ||
                                          values.posturinn_dpo_name) as
                                          | string
                                          | undefined,
                              }
                            : null;

                        createSingleSubscription({
                            shop,
                            customer: props.customer,
                            productId: values.product.id,
                            price: form.getFieldValue('price'),
                            quantity: form.getFieldValue('quantity'),
                            currencyCode: values.currency,
                            interval: intervalPayload,
                            deliveryOption: deliveryPayload,
                            paymentMethod: values.payment_method,
                            chargeNow: values.charge_now,
                            attributes: attributePayload,
                            send_order_emails: values.sendOrderEmails,
                        })
                            .then((res) => {
                                if (res.data.success) {
                                    message.success(
                                        'Tókst, áskrift hefur verið stofnuð!'
                                    );
                                    if (props.onSuccess) {
                                        props.onSuccess();
                                    }
                                } else {
                                    message.error(
                                        res.data.message ||
                                            'Ekki tókst að stofna áskrift'
                                    );
                                }

                                setLoading(false);
                            })
                            .catch((err) => {
                                message.error(
                                    err.message ||
                                        'Ekki tókst að stofna áskrift'
                                );
                                setLoading(false);
                            });
                    }}
                >
                    <Form.Item
                        initialValue={props.customer.name}
                        name="customer_name"
                        label="Viðskiptavinur"
                    >
                        <Input disabled size="large"></Input>
                    </Form.Item>

                    {
                        <Form.Item
                            name="product"
                            label="Tegund áskriftar"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Þú verður að velja tegund áskriftar',
                                },
                            ]}
                        >
                            <ProductSearchBox
                                shop={shop}
                                onChange={(res) => {
                                    console.log(res);
                                    setSelectedProduct(res);
                                    form.setFieldsValue({
                                        price:
                                            res?.prices.length === 1
                                                ? res.prices[0].amount
                                                : undefined,
                                    });
                                }}
                            ></ProductSearchBox>

                            {/* {
                                <Table
                                    dataSource={products}
                                    pagination={false}
                                    rowKey="uuid"
                                    size="small"
                                    className="white_table"
                                    showHeader={false}
                                    columns={[
                                        {
                                            title: 'Nafn',
                                            dataIndex: 'title',
                                            key: 'title',
                                            onCell: (record: Product) => {
                                                return {
                                                    onClick: () => {
                                                        console.log(record);
                                                    },
                                                };
                                            },
                                        },
                                    ]}
                                ></Table>
                            } */}

                            {/* <Select
                                showSearch={true}
                                style={{ width: '100%' }}
                                size="large"
                                placeholder="Veldu tegund áskriftar"
                                onSelect={(id: number) => {
                                    const p = products.find(
                                        (product) => product.id === id
                                    );

                                    if (p) {
                                        Axios.get(
                                            '/verslun/api/product/' +
                                                p.uuid +
                                                '/'
                                        ).then((res) => {
                                            setSelectedProduct(res.data);
                                            form.setFieldsValue({
                                                price:
                                                    res.data?.prices.length ===
                                                    1
                                                        ? res.data.prices[0]
                                                              .amount
                                                        : undefined,
                                            });
                                        });
                                    }
                                }}
                                options={products
                                    .filter(
                                        (product) =>
                                            product.type === 'SUBSCRIPTION'
                                    )
                                    .map((product) => {
                                        return {
                                            value: product.id,
                                            label: product.title,
                                        };
                                    })}
                            ></Select> */}
                        </Form.Item>
                    }

                    {selectedProduct && !selectedProduct.inventory.in_stock && (
                        <Alert
                            message="Vara ekki til á lager"
                            description="Þú getur stofnað áskrift, en þá lækkar birgðarstaða um 1 (og fer í mínus)"
                            type="warning"
                        ></Alert>
                    )}

                    <Form.Item
                        label="Magn"
                        name="quantity"
                        initialValue={1}
                        rules={[
                            {
                                required: true,
                                message: 'Þú verður að velja magn',
                            },
                        ]}
                    >
                        <InputNumber></InputNumber>
                    </Form.Item>

                    {selectedProduct && selectedProduct.delivery_config && (
                        <DeliverySelector
                            deliveryConfig={selectedProduct.delivery_config}
                            shopUuid={props.customer.shop_uuid}
                            onDroppChange={(value) => {
                                setSelectedDroppLocation(value);
                            }}
                            onSelect={(value) => {
                                setSelectedDeliveryOption(value);
                            }}
                        ></DeliverySelector>
                    )}

                    {selectedProduct &&
                        selectedProduct.attributes &&
                        selectedProduct.attributes.length > 0 &&
                        selectedProduct.attributes.map((attribute) => {
                            return (
                                <Form.Item
                                    label={attribute.label}
                                    key={attribute.uuid}
                                    name={'attribute.' + attribute.uuid}
                                    messageVariables={{ type: attribute.type }}
                                    rules={
                                        attribute.required
                                            ? [
                                                  {
                                                      required: true,
                                                      message:
                                                          'Þessi reitur er nauðsynlegur',
                                                  },
                                              ]
                                            : undefined
                                    }
                                >
                                    {attribute.type === 'SELECT' && (
                                        <Select
                                            mode={
                                                attribute.allow_multiple
                                                    ? 'tags'
                                                    : undefined
                                            }
                                        >
                                            {attribute.options.map((option) => {
                                                const extraCost =
                                                    option.prices.find(
                                                        (price) =>
                                                            price.currency
                                                                .currency_code ===
                                                            selectedCurrency
                                                    )?.amount;

                                                return (
                                                    <Select.Option
                                                        value={option.uuid}
                                                        key={option.uuid}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            <span>
                                                                {option.title}
                                                            </span>

                                                            {!!extraCost &&
                                                                selectedCurrency && (
                                                                    <small>
                                                                        +{' '}
                                                                        {displayCurrencyPrice(
                                                                            extraCost,
                                                                            selectedCurrency
                                                                        )}
                                                                    </small>
                                                                )}
                                                        </div>
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            );
                        })}

                    {selectedProduct && selectedProduct.cron_transaction && (
                        <Space direction="vertical" size={'small'}>
                            <SubscriptionCronSettings
                                align="left"
                                inputSize="large"
                                yearly={selectedProduct?.interval_combinations
                                    ?.map((c) => c.type)
                                    .includes('YEAR')}
                                monthly={selectedProduct?.interval_combinations
                                    ?.map((c) => c.type)
                                    .includes('MONTH')}
                                weekly={selectedProduct?.interval_combinations
                                    ?.map((c) => c.type)
                                    .includes('WEEK')}
                                weeklyOptions={
                                    selectedProduct?.interval_combinations?.find(
                                        (c) => c.type === 'WEEK'
                                    )?.count_options
                                }
                                monthlyOptions={
                                    selectedProduct?.interval_combinations?.find(
                                        (c) => c.type === 'MONTH'
                                    )?.count_options
                                }
                                yearlyOptions={
                                    selectedProduct?.interval_combinations?.find(
                                        (c) => c.type === 'YEAR'
                                    )?.count_options
                                }
                                onChange={(config: {
                                    interval: number;
                                    intervalType: 'MONTH' | 'YEAR' | 'WEEK';
                                }) => {
                                    setNewCronConfig({
                                        base_date_is_signup_date: false,
                                        ...(newCronConfig || {}),
                                        ...config,
                                    });
                                }}
                            ></SubscriptionCronSettings>
                            <div style={{ marginTop: 20 }}>
                                <h3>Grunn dagsetning</h3>
                                <p>
                                    Áskriftarfærslur eru stofnaðar{' '}
                                    <strong>frá og með</strong> þessum degi.{' '}
                                    <div>
                                        <strong>
                                            Stofnast strax ef þú velur daginn í
                                            dag.
                                        </strong>
                                    </div>
                                </p>
                                <Calendar
                                    fullscreen={false}
                                    onChange={(_moment) => {
                                        setNewCronConfig({
                                            ...newCronConfig,
                                            base_date_is_signup_date: false,
                                            base_date: _moment.toISOString(),
                                        });
                                    }}
                                ></Calendar>
                            </div>
                        </Space>
                    )}

                    <Form.Item
                        label="Viltu búa til sendingu og rukka núna?"
                        name="charge_now"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ"
                            unCheckedChildren="NEI"
                        ></Switch>
                    </Form.Item>

                    <Space>
                        <Form.Item
                            label="Kjör"
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Þú verður að velja kjör áskriftar',
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                onChange={(val) => setPrice(val as number)}
                            ></InputNumber>
                        </Form.Item>

                        <Form.Item
                            initialValue={'ISK'}
                            label="Gjaldmiðill"
                            name="currency"
                        >
                            <Select
                                options={[
                                    {
                                        value: 'ISK',
                                        label: 'ISK',
                                    },
                                    {
                                        value: 'EUR',
                                        label: 'EUR',
                                    },
                                    {
                                        value: 'USD',
                                        label: 'USD',
                                    },
                                ]}
                            ></Select>
                        </Form.Item>
                    </Space>

                    <Form.Item
                        name="payment_method"
                        label="Tegund greiðslu"
                        rules={[
                            {
                                required: true,
                                message: 'Þú verður að velja tegund greiðslu',
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    value: 'BANK_CLAIM',
                                    label: 'Krafa í heimabanka',
                                },
                                {
                                    value: 'CARD',
                                    label: 'Kort',
                                },
                                {
                                    value: 'FREE',
                                    label: 'Frítt',
                                },
                            ]}
                        ></Select>
                    </Form.Item>

                    <Form.Item
                        name="sendOrderEmails"
                        label="Senda staðfestingarpóst?"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ"
                            unCheckedChildren="NEI"
                        ></Switch>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={loading}
                            block
                            size="large"
                            htmlType="submit"
                            type="primary"
                            className="btn-success"
                        >
                            Staðfesta
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
}
