import { Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import Shop from '../../../interfaces/shop';
import axios from 'axios';
import { MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type Props = {
    shop: Shop;
};

export default function SMSOverview({ shop }: Props) {
    const [data, setData] = useState<
        {
            updated: string;
            created: string;
            uuid: string;
            to: string;
            user: string;
            shop: number;
            message: string;
            external_request_log: string;
        }[]
    >([]);

    useEffect(() => {
        axios
            .get('/sms/api/sms_list/?uuid=' + shop.uuid)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [shop.uuid]);

    return (
        <Card
            className="statistics_container"
            title={
                <>
                    <MessageOutlined
                        style={{
                            fontSize: 32,
                            marginRight: 5,
                            position: 'relative',
                            top: 5,
                        }}
                    />{' '}
                    Yfirlit SMS sendinga
                </>
            }
            bodyStyle={{
                justifyContent: 'flex-start',
            }}
        >
            <Table
                size="small"
                pagination={false}
                className="white_table"
                style={{ width: '100%', overflow: 'hidden' }}
                dataSource={data}
                columns={[
                    {
                        title: 'Skilaboð',
                        render: (text, record) => (
                            <div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        padding: 10,
                                        borderRadius: 20,
                                    }}
                                >
                                    {record.message}
                                </div>

                                <div
                                    style={{
                                        color: 'gray',
                                        fontSize: 13,
                                        paddingLeft: 10,
                                    }}
                                >
                                    {record.to}
                                </div>
                            </div>
                        ),
                        key: 'message',
                    },
                    {
                        render: (text, record) => (
                            <div>
                                <small>
                                    {dayjs(record.created).format(
                                        'D. MMM YYYY - HH:mm'
                                    )}
                                </small>
                            </div>
                        ),
                        key: 'created',
                    },
                ]}
            ></Table>
        </Card>
    );
}
