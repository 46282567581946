import { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Switch,
    message,
    Modal,
    InputNumber,
} from 'antd';
import ShopifyProductsOverivew from '../ShopifyProductsOverview/ShopifyProductsOverivew';
import axios from 'axios';

// Typescript
import Product from '../../interfaces/product';

type Props = {
    product: Product;
};

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

export default function ProductDKSettings(props: Props) {
    const [loading, setLoading] = useState(false);

    return (
        <div>
            {/* <div className="text-right">
                <Space>
                    <Button
                        size="small"
                        onClick={() => {
                            axios.get(
                                '/askrift/get_all_shopify_products/' +
                                    props.product.shop_uuid +
                                    '/'
                            ).then((rsp) => {
                                setShopifyProductsModalOpen(rsp.data);
                            });
                        }}
                    >
                        Shopify vörunúmeralisti
                    </Button>
                </Space>
            </div> */}
            <Form
                layout="vertical"
                onFinish={(values) => {
                    axios
                        .patch(
                            '/verslun/api/admin_product/' +
                                props.product.uuid +
                                '/',
                            values
                        )
                        .then((rsp) => {
                            message.success('Vörunúmer vistað');
                        });
                }}
                initialValues={{
                    dk_sku: props.product.dk_sku || '',
                    tax_percent: props.product.tax_percent,
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label="DK SKU"
                    name="dk_sku"
                >
                    <Input size="large" autoComplete="off" />
                </Form.Item>

                <Form.Item name="tax_percent" label="VSK prósenta">
                    <InputNumber />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Vista
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
