import { message, Skeleton, Space, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useState } from 'react';
import Subscription from '../../interfaces/subscription';
import SubscriptionCard from '../../components/SubscriptionCard/SubscriptionCard';
import Customer from '../../interfaces/customer';
import axios from 'axios';

type Props = { customer: Customer };

export default function CustomerSubscriptions({ customer }: Props) {
    const [subscriptions, setSubscriptions] = useState<
        Subscription[] | undefined
    >();
    const [loading, setLoading] = useState(true);

    const activeSubscriptions = subscriptions?.filter(
        (subscription) => !!subscription.active
    );
    const inactiveSubscriptions = subscriptions?.filter(
        (subscription) => !!!subscription.active
    );

    useEffect(() => {
        axios
            .get(
                `/askrift/api/subscription?customer__uuid=${customer.uuid}&ordering=-created`
            )
            .then((rsp) => {
                setSubscriptions(rsp.data.results);
            })
            .catch((error) => {
                message.error('Ekki tókst að sækja áskriftir');
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            setSubscriptions(undefined);
        };
    }, [customer]);

    return (
        <div>
            {loading && (
                <>
                    <Skeleton active title={false} paragraph={{ rows: 4 }} />
                </>
            )}

            <Tabs>
                <TabPane tab="Virkar" key="virkar">
                    {!activeSubscriptions?.length && (
                        <div>
                            <div
                                style={{
                                    color: '#ccc',
                                }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        style={{
                                            width: 40,
                                            opacity: 0.2,
                                            marginTop: 10,
                                        }}
                                        src="/static/images/svg/box.svg"
                                        alt="Engar virkar áskriftir"
                                    />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <div
                                        style={{
                                            color: '#ccc',
                                        }}
                                    >
                                        Engar virkar áskriftir
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <Space
                        size={15}
                        style={{ display: 'flex', gap: 20 }}
                        direction="vertical"
                    >
                        {subscriptions &&
                            activeSubscriptions &&
                            activeSubscriptions.map((subscription, key) => {
                                return (
                                    <SubscriptionCard
                                        key={key}
                                        subscription={subscription}
                                    ></SubscriptionCard>
                                );
                            })}
                    </Space>
                </TabPane>

                <TabPane tab="Óvirkar" key="ovirkar">
                    {!inactiveSubscriptions?.length && (
                        <div>
                            <div
                                style={{
                                    color: '#ccc',
                                }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        style={{
                                            width: 40,
                                            opacity: 0.2,
                                            marginTop: 10,
                                        }}
                                        src="/static/images/svg/box.svg"
                                        alt="Engar óvirkar áskriftir"
                                    />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <div
                                        style={{
                                            color: '#ccc',
                                        }}
                                    >
                                        Engar óvirkar áskriftir
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <Space
                        style={{ display: 'flex', gap: 20 }}
                        direction="vertical"
                    >
                        {subscriptions &&
                            inactiveSubscriptions &&
                            inactiveSubscriptions
                                .filter(
                                    (subscription) => !!!subscription.active
                                )
                                .map((subscription, key) => {
                                    return (
                                        <SubscriptionCard
                                            key={key}
                                            subscription={subscription}
                                        ></SubscriptionCard>
                                    );
                                })}
                    </Space>
                </TabPane>
            </Tabs>
        </div>
    );
}
