import {
    Button,
    Collapse,
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Switch,
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../authContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Product from '../../interfaces/product';
import ProductSearchBox from '../ProductOverview/ProductSearchBox';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import Shop from '../../interfaces/shop';

interface BaseSegment {
    id: string;
    name: string;
    image?: string;
    description?: string;
}

interface ProductSegment extends BaseSegment {
    product: {
        quantityEditable?: boolean;
        uuid: string;
        description?: string;
    };
    segments?: never; // ensures segments can't exist with product
}

interface CategorySegment extends BaseSegment {
    product?: never; // ensures product can't exist with segments
    segments: Segment[];
}

type Segment = ProductSegment | CategorySegment;

export default function BuildProductPicker() {
    const { selectedShop } = useContext(AuthContext);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [generatedCode, setGeneratedCode] = useState<string>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [form] = Form.useForm();
    const [reloadIframe, setReloadIframe] = useState(true);
    const [baseStyles, setBaseStyles] = useState<{
        borderRadius?: number;
        maxWidth?: number;
        margin?: string;
        colorPrimary?: string;
        imageBorderRadius?: string;
    }>({
        borderRadius: 10,
        maxWidth: 400,
        margin: '0 auto',
        colorPrimary: '#000',
        imageBorderRadius: '50px',
    });
    const [reRender, setReRender] = useState(false);
    useEffect(() => {
        if (!selectedShop) {
            return;
        }

        const code = getCode(segments, selectedShop);

        setGeneratedCode(code);

        const _reloadIframe = reloadIframe;
        setReloadIframe(!_reloadIframe);
        setTimeout(() => {
            setReloadIframe(_reloadIframe);
        }, 1000);
    }, [segments, selectedShop]);

    interface SelectOption {
        label: string;
        value: string;
        depth: number;
    }

    const insertSegment = (
        segments: Segment[],
        newSegment: Segment,
        parentId?: string
    ): Segment[] => {
        if (!parentId) {
            return [...segments, newSegment];
        }

        return segments.map((segment) => {
            if (segment.segments && segment.id === parentId) {
                return {
                    ...segment,
                    segments: [...segment.segments, newSegment],
                };
            } else if (segment.segments) {
                return {
                    ...segment,
                    segments: insertSegment(
                        segment.segments,
                        newSegment,
                        parentId
                    ),
                };
            }
            return segment;
        });
    };

    const renderSegments = (segments: Segment[]): string => {
        const renderSegment = (segment: Segment): string => {
            if (segment.segments) {
                return `{
                    name: "${segment.name}",
                    description: "${segment.description || ''}",
                    segments: [${renderSegments(segment.segments)}]
                }`;
            } else {
                return `{
                    name: "${segment.name}",
                    product: {
                        quantityEditable: ${segment.product.quantityEditable || false},
                        uuid: "${segment.product.uuid}",
                        description: "${segment.description || ''}"
                    }
                }`;
            }
        };

        return segments.map(renderSegment).join(',');
    };

    const getRepeatSettings = (segments: Segment[], shop: Shop) => {
        return `
        window.repeatSettings = {
                    shopUUID: "${shop?.uuid || ''}",
                    borderRadius: ${baseStyles.borderRadius},
                    maxWidth: ${baseStyles.maxWidth},
                    margin: "${baseStyles.margin}",
                    colorPrimary: "${baseStyles.colorPrimary}",
                    imageBorderRadius: "${baseStyles.imageBorderRadius}",
                    segments: [
                        ${renderSegments(segments)},
                    ]
                };
        `;
    };

    const getCode = (segments: Segment[], shop: Shop) => {
        return `
            <script>
                ${getRepeatSettings(segments, shop)}
            </script>
            <script type="module" crossorigin="" src="https://singleproductwidget.repeat.is/assets/repeatSingleProductToCheckout.js"></script>
            <div id="repeatSingleProductRoot"></div>`;
    };

    const addSegmentToLocation = (
        _segments: Segment[],
        newSegment: Segment,
        parentId: string | null
    ): Segment[] => {
        console.log('ADDING SEGMENT TO LOCATION', newSegment, parentId);

        // If no parent is selected, add to root level
        if (!parentId) {
            return [..._segments, newSegment];
        }

        // Recursively search and update segments
        return _segments.map((segment) => {
            if (segment.id === parentId) {
                // Found the parent category
                if (!segment.segments) {
                    throw new Error('Cannot add to a non-category segment');
                }
                return {
                    ...segment,
                    segments: [...segment.segments, newSegment],
                };
            }

            // If this segment has children, recursively search them
            if (segment.segments) {
                return {
                    ...segment,
                    segments: addSegmentToLocation(
                        segment.segments,
                        newSegment,
                        parentId
                    ),
                };
            }

            // No match found, return segment unchanged
            return segment;
        });
    };

    const getSegmentOptions = (
        segments: Segment[],
        depth = 0,
        parent: Segment | null = null
    ): SelectOption[] => {
        return segments.flatMap((segment) => {
            const option = {
                label: `${(parent?.name || '') + ' ─ '.repeat(depth)}${segment.name}`,
                value: segment.id,
                depth,
            };

            if (segment.segments) {
                // If it's a category, include both the category and its children
                return [
                    option,
                    ...getSegmentOptions(segment.segments, depth + 1, segment),
                ];
            }

            return [option];
        });
    };

    return (
        <div style={{ display: 'flex', gap: 10 }}>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <div>
                        <div>
                            <Form
                                style={{
                                    marginBottom: 20,
                                }}
                                size="large"
                                layout="vertical"
                                form={form}
                                onValuesChange={() => setReRender(!reRender)}
                                onFinish={(values) => {
                                    if (!values.segmentName) {
                                        message.error('Titill er nauðsynlegur');
                                        return;
                                    }

                                    if (
                                        values.type === 'product' &&
                                        !selectedProduct
                                    ) {
                                        message.error(
                                            'Þú verður að velja vöru'
                                        );
                                        return;
                                    }

                                    const newSegment = {
                                        id: crypto.randomUUID(),
                                        name: values.segmentName,
                                        description: values.segmentDescription,
                                        image: values.segmentImage,
                                    } as Segment;

                                    if (values.type === 'category') {
                                        newSegment.segments = [];
                                    } else {
                                        newSegment.product = {
                                            quantityEditable:
                                                values.quantityEditable,
                                            uuid: selectedProduct?.uuid || '',
                                            description:
                                                values.segmentDescription,
                                        };
                                    }

                                    const _segments = addSegmentToLocation(
                                        segments,
                                        newSegment,
                                        values.segmentParent
                                    );

                                    console.log('UPDATEDSEGMENTS', _segments);

                                    setSegments(_segments);
                                    setSelectedProduct(undefined);

                                    form.resetFields();
                                }}
                            >
                                <Form.Item
                                    label="Vara eða flokkur?"
                                    name="type"
                                >
                                    <Select>
                                        <Select.Option value="category">
                                            Flokkur
                                        </Select.Option>
                                        <Select.Option value="product">
                                            Vara
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                {selectedShop &&
                                    form.getFieldValue('type') ===
                                        'product' && (
                                        <>
                                            <Form.Item label="Vara">
                                                <ProductSearchBox
                                                    shop={selectedShop}
                                                    multiple={false}
                                                    onChange={(product) => {
                                                        setSelectedProduct(
                                                            product
                                                        );

                                                        form.setFieldsValue({
                                                            segmentName:
                                                                product.title,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Leyfa breytingu á magni"
                                                name="quantityEditable"
                                            >
                                                <Switch
                                                    checkedChildren={'Já'}
                                                    unCheckedChildren={'Nei'}
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                <Form.Item name="segmentName" label="Titill">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="segmentDescription"
                                    label="Lýsing (má vera tóm)"
                                >
                                    <Input.TextArea />
                                </Form.Item>

                                {segments.filter((s) => s.segments).length >
                                    0 && (
                                    <Form.Item
                                        label="Yfirflokkur"
                                        name="segmentParent"
                                    >
                                        <Select
                                            allowClear
                                            options={getSegmentOptions(
                                                segments
                                            )}
                                        />
                                    </Form.Item>
                                )}

                                <Button type="primary" htmlType="submit">
                                    + Bæta við
                                </Button>
                            </Form>
                        </div>

                        <div>
                            <Form
                                style={{
                                    marginBottom: 20,
                                }}
                                layout="vertical"
                                initialValues={baseStyles}
                                onValuesChange={(values) => {
                                    setBaseStyles(values);
                                }}
                            >
                                {/* Hide most of the settings under a collapsible */}
                                <Collapse>
                                    <Collapse.Panel header="Útlit" key="1">
                                        <Form.Item
                                            label="Border radius"
                                            name="borderRadius"
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Max width"
                                            name="maxWidth"
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label="Margin" name="margin">
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Primary color"
                                            name="colorPrimary"
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Image border radius"
                                            name="imageBorderRadius"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Collapse.Panel>
                                </Collapse>
                            </Form>
                        </div>

                        <div style={{ overflow: 'auto' }}>
                            <AceEditor
                                mode="html"
                                theme="monokai"
                                showPrintMargin={false}
                                width="100%"
                                setOptions={{
                                    useWorker: false,
                                }}
                                value={generatedCode}
                                onChange={(value) => {
                                    setGeneratedCode(value);
                                }}
                            ></AceEditor>
                        </div>
                    </div>
                </Space>
            </div>
            <div style={{ flex: 1 }}>
                {reloadIframe && (
                    <iframe
                        id="widget-iframe"
                        srcDoc={`
                        <!DOCTYPE html>
                        <html>
                            <body>
                                ${generatedCode}
                            </body>
                               
                        </html>
                    `}
                        style={{
                            width: '100%',

                            height: '100%',
                            border: 'none',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
