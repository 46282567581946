import React, { useState, useEffect } from 'react';
import { Switch, Modal, Button, DatePicker, Form, Radio, Alert } from 'antd';
import 'moment/locale/is';
import moment from 'moment';
import locale from '../../antd-datepicker-locale-is';
import TextArea from 'antd/lib/input/TextArea';
import Axios from 'axios';
import Subscription from '../../interfaces/subscription';
import {
    getSubscriptionCronDetails,
    getSubscriptionLifetimePotential,
} from '../../utils/subscriptionUtils';
import dayjs from 'dayjs';
import { SubscriptionTransaction } from '../../interfaces/transaction';
import Message from 'antd/lib/message';
import BeforeCancelInfo from './BeforeCancelInfo';

type Props = {
    open: boolean;
    subscription: Subscription;
    onCancel: Function;
    onSuccess: Function;
    unpaidTransactions?: SubscriptionTransaction[];
};

export default function CancelSubscriptionModal(props: Props) {
    const cancel_notice = props.subscription.product.cancel_notice;

    const [deactivateNow, setDeactivateNow] = useState(true);
    const [deleteUnpaidTransactions, setDeleteUnpaidTransactions] =
        useState(false);
    /* const [cancelDate, setCancelDate] = useState<moment.Moment | null>(null); */
    const [loading, setLoading] = useState(false);
    const [forceRender, setForceRender] = useState(true);
    const [boundUntil, setBoundUntil] = useState<dayjs.Dayjs | null>();
    const [upcomingPayments, setUpcomingPayments] = useState<dayjs.Dayjs[]>([]);
    const [mandatoryUpcomingPayments, setMandatoryUpcomingPayments] = useState<
        dayjs.Dayjs[]
    >([]);
    const [accessUntil, setAccessUntil] = useState<dayjs.Dayjs | null>();

    useEffect(() => {
        setForceRender(!forceRender);

        getSubscriptionLifetimePotential(props.subscription).then((rsp) => {
            setBoundUntil(rsp.boundUntil);
            setUpcomingPayments(rsp.upcomingPayments);
            setMandatoryUpcomingPayments(rsp.mandatoryUpcomingPayments);
            setAccessUntil(rsp.accessUntil);

            if (!rsp.boundUntil) setDeactivateNow(true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [form] = Form.useForm();

    return (
        <Modal
            open={props.open}
            title={
                <>
                    <div>Afvirkja áskrift</div>
                    <small>
                        Stofnuð{' '}
                        {dayjs(props.subscription.created).format(
                            'D. MMM YYYY'
                        )}{' '}
                        (
                        {dayjs().diff(dayjs(props.subscription.created), 'day')}{' '}
                        dagar)
                    </small>
                </>
            }
            footer={false}
            onCancel={() => props.onCancel()}
        >
            <BeforeCancelInfo
                subscription={props.subscription}
            ></BeforeCancelInfo>
            <Form
                onValuesChange={() => {
                    setForceRender(!forceRender);
                }}
                form={form}
                onFinish={(values) => {
                    let resign_date: string | null;

                    resign_date = values.resign_date
                        ? values.resign_date.toISOString()
                        : null;

                    setLoading(true);

                    const updatePath = 'subscription';

                    Axios.patch(
                        `/askrift/api/${updatePath}/${props.subscription.uuid}/`,
                        {
                            ...values,
                            active: resign_date ? true : !deactivateNow,
                            resign_date,
                        }
                    ).then(() => {
                        if (deleteUnpaidTransactions) {
                            const payload = {
                                is_deleted: true,
                                selection: {
                                    shop_uuid:
                                        props.subscription.customer.shop_uuid,
                                    allSelected: false,
                                    selectionList:
                                        props.unpaidTransactions?.map(
                                            (s) => s.id
                                        ),
                                },
                            };

                            Axios.post(
                                '/askrift/update_subscription_transactions/',
                                payload
                            ).then((rsp) => {
                                props.onSuccess({
                                    active: resign_date ? true : !deactivateNow,
                                    resign_date,
                                    cancel_reason: values.cancel_reason,
                                    uuid: props.subscription.uuid,
                                    transactionsDeleted: true,
                                });
                            });
                        } else {
                            props.onSuccess({
                                active: resign_date ? true : !deactivateNow,
                                resign_date,
                                cancel_reason: values.cancel_reason,
                                uuid: props.subscription.uuid,
                                transactionsDeleted: false,
                            });
                        }
                    });
                }}
                layout="vertical"
            >
                <Form.Item
                    name="active"
                    initialValue={deactivateNow}
                    label="Viltu afvirkja áskriftina strax?"
                    valuePropName="checked"
                >
                    <>
                        <Radio.Group
                            size="large"
                            onChange={(e) => {
                                setDeactivateNow(e.target.value);
                                form.setFieldsValue({
                                    deactivate_now: e.target.value,
                                });
                            }}
                            value={deactivateNow}
                        >
                            <Radio.Button value={true}>
                                Já, afvikja strax
                            </Radio.Button>
                            <Radio.Button value={false}>
                                Nei, á dagsetningu
                            </Radio.Button>
                        </Radio.Group>
                    </>
                </Form.Item>
                {!deactivateNow && (
                    <Form.Item
                        name="resign_date"
                        label="Afvirkja sjálfkrafa á dagsetningu"
                        initialValue={moment().add(cancel_notice, 'months')}
                    >
                        <DatePicker
                            size="large"
                            allowClear={false}
                            // @ts-ignore
                            locale={locale}
                            /* onChange={(m) => {
                                        setCancelDate(m);
                                    }} */
                            format="ll"
                            showToday={false}
                        />
                    </Form.Item>
                )}
                {deactivateNow && !!props.unpaidTransactions?.length && (
                    <Form.Item
                        name="delete_unpaid_transactions"
                        label="Viltu eyða ógreiddum færslum?"
                        initialValue={false}
                    >
                        <Radio.Group
                            size="large"
                            onChange={(e) => {
                                setDeleteUnpaidTransactions(e.target.value);
                                form.setFieldsValue({
                                    delete_unpaid_transactions: e.target.value,
                                });
                            }}
                            value={deleteUnpaidTransactions}
                        >
                            <Radio.Button value={true}>
                                Já, eyða færslum
                            </Radio.Button>
                            <Radio.Button value={false}>
                                Nei, halda áfram að reyna að rukka
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                )}
                <Form.Item
                    name="notify_customer"
                    initialValue={true}
                    label="Viltu senda tilkynningu á viðskiptavininn?"
                    valuePropName="checked"
                >
                    <>
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            defaultChecked={true}
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    notify_customer: checked,
                                });
                            }}
                        />
                    </>
                </Form.Item>

                <Form.Item name="cancel_reason" label="Ástæða uppsagnar">
                    <TextArea rows={5}></TextArea>
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={loading}
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                    >
                        Staðfesta
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
