import { useState } from 'react';
import Payment from '../../interfaces/payment';
import { displayCurrencyPrice } from '../../utils';
import {
    CreditCardFilled,
    EuroCircleFilled,
    MessageOutlined,
} from '@ant-design/icons';
import { SALT_PAY_CODES } from '../../salt_pay_codes';
import RefundPaymentTransaction from '../RefundPaymentTransaction/RefundPaymentTransaction';

import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import languages, { LanguageKey } from '../../translate/translate';
import { Button, Modal } from 'antd';

interface IWindow extends Window {
    me?: number;
}
const w: IWindow = window;

type Props = {
    paymentTransaction: Payment;
    isPublic: boolean;
    language: LanguageKey;
    onRefundSuccess: () => void;
    isTimeline?: boolean;
    color?: string;
};

export default function PaymentTransactionDetails({
    paymentTransaction,
    isPublic,
    language,
    onRefundSuccess,
    isTimeline,
}: Props) {
    const [refundModal, setRefundModal] = useState<
        | {
              paymentTransaction: Payment;
              onSuccess: () => void;
              onCancel: () => void;
          }
        | false
    >(false);

    const { response, endpoint, payload, amount = 0 } = paymentTransaction;

    const requestType = paymentTransaction.request_type;

    const isRefundAttempt =
        requestType === 'REFUND' || endpoint.includes('refund');

    const isOnPayAuthorize =
        endpoint.includes('authorize') && endpoint.includes('onpay.io');

    let { VerifyCardResult, ActionCode, Message, Amount } = response;
    ActionCode = VerifyCardResult?.ActionCode || ActionCode;
    Message = Message || SALT_PAY_CODES?.[ActionCode];

    const isSuccess =
        ActionCode === '000' ||
        response.isSuccess ||
        response?.data?.paid ||
        response?.data?.status === 'finished' ||
        response?.data?.status === 'active' ||
        response?.data?.status?.toLowerCase() === 'success' ||
        response?.status === 'AUTHORIZED' ||
        response?.status === 'REFUNDED' ||
        response?.enrolled === 'Y' ||
        response?.token_status === 'ACTIVE';

    Amount =
        Amount ||
        payload?.Amount ||
        amount ||
        response.Amount ||
        response.amount
            ? amount
            : 0;

    let responseMessage;

    let provider;

    if (endpoint.includes('valitor')) {
        provider = 'valitor';
        responseMessage = response?.responseDescription;
    } else if (endpoint.includes('borgun')) {
        provider = 'teya';
        responseMessage = Message;
    } else if (endpoint.includes('verifone')) {
        provider = 'verifone';
        responseMessage = response?.reason_desc;
    }

    const isRefundReady = (endpoint: string) => {
        return (
            endpoint.includes('rpg/api/payment') ||
            endpoint.includes('VirtualCardPayment') ||
            endpoint.includes('api.rapyd.net/v1/payments') ||
            endpoint.includes('verifone.cloud/oidc/api/v2/transactions')
        );
    };

    return (
        <>
            <div
                className={`rpgMessageWrapper ${
                    isSuccess
                        ? isRefundAttempt
                            ? 'rpgWarning'
                            : 'rpgSuccess'
                        : 'rpgError'
                }
        `}
            >
                <div style={{ color: '#a2a2a2', textAlign: 'right' }}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {!paymentTransaction.is_refunded &&
                            isSuccess &&
                            !isPublic &&
                            !isRefundAttempt &&
                            isRefundReady(endpoint) ? (
                                <Button
                                    style={{
                                        color: 'gray',
                                        fontSize: 12,
                                        background: isTimeline
                                            ? 'rgba(0, 0, 0, 0.028)'
                                            : 'transparent',
                                    }}
                                    onClick={() => {
                                        setRefundModal({
                                            paymentTransaction,
                                            onCancel: () => {
                                                setRefundModal(false);
                                            },
                                            onSuccess: () => {
                                                onRefundSuccess();
                                            },
                                        });
                                    }}
                                    size="small"
                                    type="text"
                                >
                                    {' '}
                                    {languages('refund', language)}
                                </Button>
                            ) : (
                                <div></div>
                            )}
                            {!isTimeline &&
                                dayjs(paymentTransaction.created).format(
                                    'D. MMM YYYY - HH:mm'
                                )}
                        </div>
                    </div>
                </div>
                <div className={'rpgLine'}></div>
                {responseMessage && (
                    <div
                        style={{
                            color: '#a2a2a2',
                            padding: 5,
                            fontSize: 12,
                        }}
                    >
                        {responseMessage}
                    </div>
                )}

                <div
                    className="rpgMessageContent"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                    }}
                >
                    {/* Card token creation */}
                    {(endpoint.includes('/token/multi') ||
                        requestType === 'CREATE_CARD_TOKEN' ||
                        endpoint.includes('CreateVirtualCard')) && (
                        <div>
                            <CreditCardFilled className="rpgResIcon" />{' '}
                            {languages('card_saved', language)}
                        </div>
                    )}

                    {/* OnPay authorization request */}
                    {isOnPayAuthorize && (
                        <div>
                            <CreditCardFilled className="rpgResIcon" />{' '}
                            {languages('applied_for_authorization', language)}
                        </div>
                    )}

                    {/* Card payment */}
                    {!isRefundAttempt &&
                        (endpoint.includes('rpg/api/payment') ||
                            endpoint.includes('VirtualCardPayment') ||
                            requestType === 'PAYMENT') && (
                            <div>
                                <EuroCircleFilled className="rpgResIcon" />{' '}
                                {languages('card_charged', language)} (
                                {displayCurrencyPrice(
                                    Amount / 100,
                                    paymentTransaction.currency_code
                                )}
                                ){' '}
                            </div>
                        )}

                    {/* Card payment */}
                    {requestType === '3DSECURE' && (
                        <div>
                            <EuroCircleFilled className="rpgResIcon" /> 3DSecure
                        </div>
                    )}

                    {/* Succsessfully refunded payment */}
                    {isRefundAttempt && isSuccess && (
                        <div>
                            <EuroCircleFilled className="rpgResIcon" />{' '}
                            {languages('refund_action', language) +
                                ' (' +
                                displayCurrencyPrice(
                                    (response.RefundAmount ||
                                        response.authorizedAmount ||
                                        1) / 100 ||
                                        response?.data?.amount ||
                                        0,
                                    paymentTransaction.currency_code
                                ) +
                                ')'}
                        </div>
                    )}

                    {/* Unsuccessfully refunded payment */}
                    {isRefundAttempt && !isSuccess && (
                        <div>
                            <EuroCircleFilled className="rpgResIcon" />{' '}
                            {languages('refund_action', language) +
                                ' (' +
                                displayCurrencyPrice(
                                    (response.RefundAmount ||
                                        response.authorizedAmount ||
                                        1) / 100 || 0,
                                    paymentTransaction.currency_code
                                ) +
                                ')'}
                        </div>
                    )}
                </div>

                {paymentTransaction.description && (
                    <Button
                        onClick={() => {
                            Modal.info({
                                content: paymentTransaction.description,
                            });
                        }}
                        size="small"
                    >
                        <MessageOutlined />
                        {languages('comment', language)}
                    </Button>
                )}
                {w.me === 1 && paymentTransaction.transaction && (
                    <Button
                        type="dashed"
                        size="small"
                        href={`/bakendi/subscription/subscriptiontransaction/${paymentTransaction.transaction}/change/`}
                    >
                        Transaction
                    </Button>
                )}
            </div>

            {!!refundModal && (
                <Modal
                    footer={null}
                    open={!!refundModal}
                    onCancel={() => {
                        setRefundModal(false);
                    }}
                >
                    <RefundPaymentTransaction
                        onCancel={refundModal.onCancel}
                        onSuccess={refundModal.onSuccess}
                        paymentTransaction={refundModal.paymentTransaction}
                    ></RefundPaymentTransaction>
                </Modal>
            )}
        </>
    );
}
