import { useState, ReactElement } from 'react';
import {
    Card,
    Badge,
    Switch,
    Alert,
    Button,
    Collapse,
    Space,
    Tag,
    Modal,
    Select,
    message,
    Result,
    Form,
    Table,
} from 'antd';
import {
    EditOutlined,
    CreditCardFilled,
    ClockCircleOutlined,
    SolutionOutlined,
    LinkOutlined,
    NumberOutlined,
    CodeSandboxOutlined,
    HistoryOutlined,
    PauseCircleOutlined,
    PauseCircleFilled,
    PlayCircleOutlined,
    ProductOutlined,
} from '@ant-design/icons';
import { displayCurrencyPrice, kr } from '../../utils';
import EditSubscriptionProductModal from '../EditSubscriptionProductModal/EditSubscriptionProductModal';
import CancelSubscriptionModal from '../CancelSubscriptionModal/CancelSubscriptionModal';

import { getSubscriptionPaymentString } from '../../utils/subscriptionUtils';
import SubscriptionHistory from '../SubscriptionHistory/SubscriptionHistory';

/* Interfaces */
import Subscription from '../../interfaces/subscription';
import FrequencyDisplayCard from '../FrequencyDisplayCard/FrequencyDisplayCard';
import DeliveryChoices from './DeliveryChoices';
import ReactivateSubscription from '../PublicComponents/ReactivateSubscription/ReactivateSubscription';
import brandingConfig from '../../utils/brandingConfig';

import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import SelectedAttributeInfo from '../SelectedAttributeInfo/SelectedAttributeInfo';
import SubscriptionAttributeOptions from '../SubscriptionAttributeOptions/SubscriptionAttributeOptions';
import axios from 'axios';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { OrderProduct } from '../../interfaces/order';
import EditSubscriptionProductQuantityModal from '../EditSubscriptionProductModal/EditSubscriptionProductQuantityModal';
import Product from '../../interfaces/product';
dayjs.locale('is'); // use locale

const { Panel } = Collapse;

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

interface Props {
    subscription: Subscription;
}

export default function SubscriptionCard(props: Props): ReactElement {
    const [loading, setLoading] = useState(false);

    const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState<
        Subscription | false
    >(false);
    const [editSubscriptionModal, setEditSubscriptionModal] = useState<
        | {
              subscription_product: OrderProduct;
              product: Product;
          }
        | false
    >(false);

    const [editQuantityModal, setEditQuantityModal] = useState<
        Subscription | false
    >(false);
    const [subscription, setSubscription] = useState(props.subscription);

    const [reactivateSubscriptionModal, setReactivateSubscriptionModal] =
        useState<Subscription | false>(false);

    const [subscriptionHistoryModal, setSubscriptionHistoryModal] = useState<
        false | string
    >(false);

    const [subscriptionAttributeOptions, setSubscriptionAttributeOptions] =
        useState<Subscription | false>(false);

    const specialStyle =
        subscription.active && subscription.resign_date
            ? { background: brandingConfig.warningColor }
            : {};

    const getCreationMethodString = (
        creationMethod: Subscription['creation_method']
    ) => {
        if (creationMethod === 'EXCEL_IMPORT') {
            return 'Flutt inn með Excel';
        }

        if (creationMethod === 'GIFT_CERTIFICATE') {
            return 'Gjafabréf';
        }

        if (creationMethod === 'SHOP_OWNER') {
            return 'Stofnað handvirkt af starfsmanni';
        }

        if (creationMethod === 'REPEAT_CHECKOUT') {
            return 'Greiðslusíða Repeat';
        }

        if (creationMethod === 'MY_SUBSCRIPTION_PAGE') {
            return 'Á mínum síðum';
        }

        if (creationMethod === 'OLD_CHECKOUT') {
            return 'Með eldri tegund greiðslusíðu';
        }

        if (creationMethod === 'REPEAT_CHECKOUT_WITH_SHOPIFY_CART') {
            return 'Repeat greiðslusíða - með Shopify körfu';
        }

        if (creationMethod === 'SUBSCRIPTION_DRAFT') {
            return 'Búið til útfrá áskriftardrögum';
        }

        return creationMethod;
    };

    return (
        <>
            <Card
                loading={loading}
                className="ant-card-gray"
                title={
                    <>
                        <div>
                            <Badge
                                /* @ts-ignore */
                                status={
                                    specialStyle.background
                                        ? specialStyle.background
                                        : subscription.active
                                          ? 'success'
                                          : 'error'
                                }
                            />{' '}
                            {w.me === 1 && (
                                <Button
                                    size="small"
                                    href={`/bakendi/subscription/subscription/${subscription?.id}/change/`}
                                >
                                    Breyta
                                </Button>
                            )}
                            {w.me === 1 &&
                                !!subscription.history &&
                                !!subscription.history.length && (
                                    <span>
                                        <HistoryOutlined
                                            onClick={() => {
                                                if (subscription.history)
                                                    setSubscriptionHistoryModal(
                                                        subscription.uuid
                                                    );
                                            }}
                                        />
                                    </span>
                                )}
                            {(subscription.subscription_product.quantity || 1) >
                                1 && (
                                <span>
                                    {subscription.subscription_product.quantity}
                                    x{' '}
                                </span>
                            )}{' '}
                            <a
                                style={{ color: 'black' }}
                                href={`/breyta_voru/${subscription.product.uuid}`}
                            >
                                {subscription.product.title}
                            </a>
                        </div>
                        <div
                            style={{
                                fontWeight: 'initial',
                                fontSize: 12,
                                marginLeft: 20,
                            }}
                        >
                            Stofnað{' '}
                            <span>
                                {dayjs(subscription.created).format(
                                    'D. MMM YYYY - HH:mm'
                                )}
                            </span>
                            {' - '}
                            <span>
                                (
                                {getCreationMethodString(
                                    subscription.creation_method
                                )}
                                )
                            </span>
                        </div>
                    </>
                }
                extra={
                    <>
                        Áskrift virk?{' '}
                        <Switch
                            style={specialStyle}
                            checked={subscription.active}
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                if (subscription.active) {
                                    setCancelSubscriptionModal(subscription);
                                }
                            }}
                        />
                    </>
                }
            >
                <div>
                    {subscription.is_paused && (
                        <Card
                            style={{
                                background: '#f0f0f0',
                            }}
                        >
                            <Result
                                style={{
                                    padding: 20,
                                }}
                                title="Áskriftin er í pásu"
                                subTitle={
                                    <>
                                        <p>
                                            Engar rukkanir munu eiga sér stað
                                            fyrr en kveikt hefur verið á henni.
                                            Athugið að áfram verður reynt að
                                            innheimta ógreiddar sendingar sem
                                            þegar hafa verið stofnaðar.
                                        </p>

                                        <p>
                                            Athugið að pásan er ekki tekin gild
                                            ef sendingar eru stofnaðar
                                            handvirkt.
                                        </p>
                                    </>
                                }
                                extra={
                                    <Button
                                        onClick={() => {
                                            setLoading(true);
                                            axios
                                                .patch(
                                                    `/askrift/api/subscription/${subscription.uuid}/`,
                                                    {
                                                        is_paused: false,
                                                    }
                                                )
                                                .then((rsp) => {
                                                    setSubscription({
                                                        ...subscription,
                                                        is_paused: false,
                                                    });

                                                    setLoading(false);

                                                    message.success('Tókst');
                                                })
                                                .catch((err) => {
                                                    setLoading(false);
                                                    message.error(
                                                        'Eitthvað fór úrskeiðis'
                                                    );
                                                });
                                        }}
                                        type="primary"
                                    >
                                        <PlayCircleOutlined /> Kveikja aftur
                                    </Button>
                                }
                                status="warning"
                            />
                        </Card>
                    )}
                    {!!subscription.unpaid_subscription_transactions
                        ?.length && (
                        <div
                            style={{
                                padding: 10,
                                borderRadius: 20,
                                textAlign: 'center',
                                background: 'rgba(255,0,0,0.11)',
                                marginBottom: 20,
                            }}
                        >
                            <h5>
                                Ógreiddar sendingar:{' '}
                                {
                                    subscription
                                        .unpaid_subscription_transactions.length
                                }
                            </h5>
                            {subscription.unpaid_subscription_transactions.map(
                                (subscriptonTransaciton) => {
                                    return (
                                        <Tag
                                            key={subscriptonTransaciton.id}
                                            style={{
                                                border: 'none',
                                                borderRadius: 20,
                                                background: '#ffa9a9',
                                                color: 'white',
                                            }}
                                        >
                                            {dayjs(
                                                subscriptonTransaciton.created
                                            ).format('D. MMM YYYY')}
                                        </Tag>
                                    );
                                }
                            )}
                        </div>
                    )}
                    {subscription.product.attributes &&
                        subscription.product.attributes.length > 0 && (
                            <div
                                style={{
                                    border: '1px solid #d9d9d9',
                                    padding: 10,
                                    borderRadius: 10,
                                    background: '#efefef',
                                    color: '#585858',
                                    textAlign: 'center',
                                    fontSize: 12,
                                }}
                            >
                                {subscription.subscription_product.attributes?.map(
                                    (attribute) => {
                                        return (
                                            <SelectedAttributeInfo
                                                orderAttribute={attribute}
                                                attribute={attribute.attribute}
                                                key={attribute.attribute.uuid}
                                                tagBgColor="#fff"
                                                fontSize={14}
                                            ></SelectedAttributeInfo>
                                        );
                                    }
                                )}

                                {(subscription.subscription_product.attributes
                                    ?.length || 0) < 1 && (
                                    <div>
                                        <span>
                                            Þessi vara bíður upp á eigindi en
                                            engin eru valin.
                                        </span>
                                    </div>
                                )}

                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            setSubscriptionAttributeOptions(
                                                subscription
                                            )
                                        }
                                        type="link"
                                        size="small"
                                    >
                                        Breyta
                                    </Button>
                                </div>
                            </div>
                        )}
                    <Collapse
                        defaultActiveKey={['custom_data']}
                        bordered={false}
                    >
                        <Panel
                            extra={<CreditCardFilled />}
                            header={
                                <>
                                    Tegund greiðslu:{' '}
                                    <strong>
                                        {getSubscriptionPaymentString(
                                            subscription
                                        )}
                                    </strong>
                                </>
                            }
                            key="payment"
                        >
                            <div>
                                {subscription.active ? (
                                    <div>
                                        <Select
                                            size="large"
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={(value) => {
                                                console.log(value);

                                                axios
                                                    .post(
                                                        '/askrift/api/update_subscription_payment_method/',
                                                        {
                                                            subscription_uuid:
                                                                subscription.uuid,
                                                            payment_method:
                                                                value,
                                                        }
                                                    )
                                                    .then((rsp) => {
                                                        message.success(
                                                            'Greiðslumáti uppfærður'
                                                        );
                                                        setSubscription({
                                                            ...subscription,
                                                            payment_method:
                                                                rsp.data
                                                                    .new_payment_method,
                                                        });
                                                    });
                                            }}
                                            defaultValue={
                                                subscription.payment_method ===
                                                'BANK_CLAIM'
                                                    ? 'bank_claim'
                                                    : 'card'
                                            }
                                        >
                                            <Select.Option value="card">
                                                Kort
                                            </Select.Option>
                                            <Select.Option value="bank_claim">
                                                Krafa
                                            </Select.Option>
                                        </Select>
                                    </div>
                                ) : (
                                    'Ekki er hægt að breyta áskrift sem hefur verið afvirkjuð.'
                                )}
                            </div>
                        </Panel>
                        <Panel
                            extra={<SolutionOutlined />}
                            header={
                                <>
                                    Kjör viðskiptavinar{' '}
                                    <strong>
                                        {displayCurrencyPrice(
                                            subscription.subscription_product
                                                .price,
                                            subscription.subscription_product
                                                .currency_code
                                        )}
                                    </strong>
                                </>
                            }
                            key="3"
                        >
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    <Button
                                        block
                                        onClick={() => {
                                            setEditSubscriptionModal({
                                                subscription_product:
                                                    subscription.subscription_product,
                                                product: subscription.product,
                                            });
                                        }}
                                    >
                                        Breyta
                                        <EditOutlined />
                                    </Button>
                                </span>
                            </div>
                        </Panel>
                        {subscription.product.delivery_config && (
                            <Panel
                                extra={<CodeSandboxOutlined />}
                                header="Afhendingamáti"
                                key="delivery"
                            >
                                <DeliveryChoices
                                    subscription={subscription}
                                ></DeliveryChoices>
                            </Panel>
                        )}
                        <Panel
                            key="quantity"
                            header={
                                <>
                                    Magn:{' '}
                                    <strong>
                                        {
                                            subscription.subscription_product
                                                .quantity
                                        }
                                    </strong>
                                </>
                            }
                            extra={<NumberOutlined />}
                        >
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    <Button
                                        block
                                        onClick={() => {
                                            setEditQuantityModal(subscription);
                                        }}
                                    >
                                        Breyta
                                        <EditOutlined />
                                    </Button>
                                </span>
                            </div>
                        </Panel>
                        <Panel
                            key="frequency"
                            header="Tíðni sendinga"
                            extra={<ClockCircleOutlined />}
                        >
                            <FrequencyDisplayCard
                                subscription={subscription}
                            ></FrequencyDisplayCard>
                        </Panel>
                        {!subscription.is_paused && (
                            <Panel
                                header="Pása áskrift"
                                key="pause_subscription"
                                extra={<PauseCircleOutlined />}
                            >
                                <Button
                                    block
                                    onClick={() => {
                                        setLoading(true);
                                        axios
                                            .patch(
                                                `/askrift/api/subscription/${subscription.uuid}/`,
                                                {
                                                    is_paused: true,
                                                }
                                            )
                                            .then((rsp) => {
                                                setSubscription({
                                                    ...subscription,
                                                    is_paused: true,
                                                });

                                                setLoading(false);

                                                message.success('Tókst');
                                            })
                                            .catch((err) => {
                                                setLoading(false);
                                                message.error(
                                                    'Eitthvað fór úrskeiðis'
                                                );
                                            });
                                    }}
                                >
                                    <PauseCircleFilled />
                                    Pása áskrift
                                </Button>
                            </Panel>
                        )}
                        {subscription?.product?.is_podcast && (
                            <Panel
                                header="Podcast RSS hlekkur"
                                key="podcast"
                                extra={<LinkOutlined />}
                            >
                                <div>
                                    https://repeat.is/podcast/
                                    {subscription.uuid}/
                                </div>
                            </Panel>
                        )}
                        {subscription.external_ref && (
                            <Panel
                                showArrow={false}
                                collapsible="icon"
                                key="audkenni"
                                header={
                                    <>
                                        Söluauðkenni:{' '}
                                        {subscription.external_ref}
                                    </>
                                }
                            ></Panel>
                        )}
                        {subscription.subscription_product.custom_data && (
                            <Panel
                                collapsible="icon"
                                key="custom_data"
                                header="Auka gögn"
                                extra={<CodeSandboxOutlined />}
                            >
                                <CustomDataForm
                                    custom_data={
                                        subscription.subscription_product
                                            .custom_data
                                    }
                                    subscriptionProduct={
                                        subscription.subscription_product
                                    }
                                ></CustomDataForm>
                            </Panel>
                        )}

                        {subscription.add_ons &&
                            subscription.add_ons.length > 0 && (
                                <Panel
                                    collapsible="icon"
                                    key="add_ons"
                                    header="Viðbótarvörur"
                                    extra={<ProductOutlined />}
                                >
                                    {subscription.add_ons && (
                                        <Table
                                            className="white_table"
                                            size="small"
                                            pagination={false}
                                            dataSource={subscription.add_ons}
                                            expandable={{
                                                expandedRowRender: (record) => {
                                                    return (
                                                        <div>
                                                            <CustomDataForm
                                                                subscriptionProduct={
                                                                    record
                                                                }
                                                                custom_data={
                                                                    record.custom_data
                                                                }
                                                            ></CustomDataForm>
                                                        </div>
                                                    );
                                                },
                                                defaultExpandAllRows: true,
                                            }}
                                            columns={[
                                                {
                                                    title: 'Vara',
                                                    render: (obj) => {
                                                        return obj.product
                                                            .title;
                                                    },
                                                },
                                                {
                                                    title: 'Verð',
                                                    render: (obj) => {
                                                        return (
                                                            <div>
                                                                {displayCurrencyPrice(
                                                                    obj.price,
                                                                    obj.currency_code
                                                                )}{' '}
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setEditSubscriptionModal(
                                                                            {
                                                                                subscription_product:
                                                                                    obj,
                                                                                product:
                                                                                    obj.product,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditOutlined />{' '}
                                                                    Breyta
                                                                </Button>
                                                            </div>
                                                        );
                                                    },
                                                },
                                            ]}
                                        ></Table>
                                        //                 {/* Alls:{' '}
                                        // {displayCurrencyPrice(
                                        //     subscription.add_ons.reduce(
                                        //         (a, b) => a + b.price,
                                        //         0
                                        //     ) + subscription.subscription_product.price,
                                        //     subscription.subscription_product.currency_code
                                        // )} */}
                                    )}
                                </Panel>
                            )}

                        {/* {props.isAdmin &&
                        subscription.product.appwrite_user_profiles && (
                            <Panel
                                key="appwrite"
                                header="Notenda aðgangur"
                                extra={<UserOutlined />}
                            >
                                <Button block>
                                    <KeyOutlined></KeyOutlined> Endurnýja
                                    lykilorð
                                </Button>
                            </Panel>
                        )} */}
                    </Collapse>
                </div>

                <div>
                    {subscription.resign_date && subscription.active && (
                        <div>
                            <br />
                            <Alert
                                message={
                                    <Space>
                                        {`Þessi áskrift verður sjálfkrafa óvirkjuð ${dayjs(
                                            subscription.resign_date
                                        ).format('D. MMM YYYY')}`}

                                        <Button
                                            onClick={() =>
                                                setReactivateSubscriptionModal(
                                                    subscription
                                                )
                                            }
                                            size="small"
                                        >
                                            Hætta við að hætta
                                        </Button>
                                    </Space>
                                }
                                type="warning"
                            />
                            <br />
                        </div>
                    )}

                    {subscription.cancel_reason &&
                        (subscription.resign_date || !subscription.active) && (
                            <div>
                                <Alert
                                    message="Ástæða uppsagnar"
                                    description={subscription.cancel_reason}
                                    type="info"
                                />
                            </div>
                        )}
                </div>
            </Card>

            {
                /* Modals */

                !!cancelSubscriptionModal && (
                    <CancelSubscriptionModal
                        open={!!cancelSubscriptionModal}
                        subscription={cancelSubscriptionModal}
                        onCancel={() => {
                            setCancelSubscriptionModal(false);
                        }}
                        unpaidTransactions={
                            subscription.unpaid_subscription_transactions
                        }
                        onSuccess={(obj: {
                            uuid: string;
                            active: boolean;
                            cancel_reason: string;
                            resign_date: string;
                            transactionsDeleted: boolean;
                        }) => {
                            setSubscription({
                                ...subscription,
                                active: obj.active,
                                resign_date: obj.resign_date,
                                cancel_reason: obj.cancel_reason,
                                unpaid_subscription_transactions: undefined,
                            });
                            setCancelSubscriptionModal(false);
                        }}
                    ></CancelSubscriptionModal>
                )
            }

            {reactivateSubscriptionModal && (
                <ReactivateSubscription
                    subscription={subscription}
                    onCancel={() => setReactivateSubscriptionModal(false)}
                    onSuccess={() => {
                        setSubscription({
                            ...subscription,
                            active: true,
                            cancel_reason: undefined,
                            resign_date: undefined,
                        });
                        setReactivateSubscriptionModal(false);
                    }}
                ></ReactivateSubscription>
            )}

            {!!editSubscriptionModal && (
                <EditSubscriptionProductModal
                    open={!!editSubscriptionModal}
                    subscriptionProduct={
                        editSubscriptionModal.subscription_product
                    }
                    product={editSubscriptionModal.product}
                    onCancel={() => {
                        setEditSubscriptionModal(false);
                    }}
                    onSuccess={(
                        newPrice: number,
                        subscriptionProduct: OrderProduct,
                        productType: string
                    ) => {
                        console.log(subscriptionProduct);
                        if (productType !== 'ADD_ON') {
                            setSubscription({
                                ...subscription,
                                subscription_product: {
                                    ...subscription.subscription_product,
                                    price: newPrice,
                                },
                            });
                        } else {
                            setSubscription({
                                ...subscription,
                                add_ons: subscription.add_ons?.map((addOn) => {
                                    if (addOn.id === subscriptionProduct.id) {
                                        return {
                                            ...addOn,
                                            price: newPrice,
                                        };
                                    }

                                    return addOn;
                                }),
                            });
                        }
                        setEditSubscriptionModal(false);
                    }}
                ></EditSubscriptionProductModal>
            )}

            {!!editQuantityModal && (
                <EditSubscriptionProductQuantityModal
                    open={!!editQuantityModal}
                    subscriptionProduct={editQuantityModal.subscription_product}
                    onCancel={() => setEditQuantityModal(false)}
                    onSuccess={(newQuantity: number) => {
                        setSubscription({
                            ...subscription,
                            subscription_product: {
                                ...subscription.subscription_product,
                                quantity: newQuantity,
                            },
                        });
                        setEditQuantityModal(false);
                    }}
                ></EditSubscriptionProductQuantityModal>
            )}

            {!!subscriptionHistoryModal && (
                <Modal
                    open={!!subscriptionHistoryModal}
                    onCancel={() => setSubscriptionHistoryModal(false)}
                >
                    <SubscriptionHistory
                        uuid={subscriptionHistoryModal}
                    ></SubscriptionHistory>
                </Modal>
            )}

            {
                <Modal
                    footer={false}
                    onCancel={() => setSubscriptionAttributeOptions(false)}
                    open={!!subscriptionAttributeOptions}
                >
                    {subscriptionAttributeOptions && (
                        <SubscriptionAttributeOptions
                            subscription={subscriptionAttributeOptions}
                            onSuccess={() => window.location.reload()}
                        ></SubscriptionAttributeOptions>
                    )}
                </Modal>
            }
        </>
    );
}

const CustomDataForm = (props: {
    custom_data: any;
    subscriptionProduct: OrderProduct;
}): ReactElement => {
    const [customData, setCustomData] = useState(
        JSON.stringify(props.custom_data, null, 2)
    );

    const [loading, setLoading] = useState(false);

    return (
        <Form
            layout="vertical"
            onFinish={(values) => {
                setLoading(true);

                // Checking if the custom data is valid JSON
                try {
                    JSON.parse(customData);
                } catch (e) {
                    setLoading(false);
                    message.error('Ekki tókst að uppfæra gögn, ógilt JSON.');
                    return;
                }

                axios
                    .patch(
                        `/askrift/api/subscription_product/${props.subscriptionProduct.id}/`,
                        {
                            custom_data: JSON.parse(customData),
                        }
                    )
                    .then((rsp) => {
                        setLoading(false);
                        message.success('Gögn uppfærð');
                    })
                    .catch((err) => {
                        setLoading(false);
                        message.error('Eitthvað fór úrskeiðis');
                    });
            }}
        >
            <AceEditor
                mode="json"
                theme="monokai"
                onChange={(value) => setCustomData(value)}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                value={customData}
                style={{
                    width: '100%',
                    height: 200,
                    marginBottom: 20,
                }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                }}
            />

            {customData !== JSON.stringify(props.custom_data, null, 2) && (
                <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block
                >
                    Uppfæra
                </Button>
            )}
        </Form>
    );
};
