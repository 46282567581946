import { useEffect, useState } from 'react';
import Shop from '../../interfaces/shop';

import axios from 'axios';
import { Alert, Button, Form, Input, message, Space, Switch } from 'antd';

type Props = {
    shop: Shop;
};

export default function DKSettings({ shop }: Props) {
    const [form] = Form.useForm();
    const [active, setActive] = useState(!!shop?.dk_token ? true : false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            dk_token: shop.dk_token,
            active: !!!shop?.dk_token ? true : false,
        });

        return () => {
            form.resetFields();
        };
    }, [shop]);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                dk_token: shop?.dk_token || '',
            }}
            onFinish={(values) => {
                setLoading(true);
                axios
                    .patch('/verslun/api/update_shop/' + shop.uuid + '/', {
                        dk_token: active ? values.dk_token : '',
                    })
                    .then(() => {
                        message.success('Tókst! 🥳');
                    })
                    .catch(() => {
                        message.error('Það kom upp villa :/');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        >
            <Space
                direction="vertical"
                style={{ display: 'flex' }}
                size="large"
            >
                <Alert
                    message="Stofna þarf API lykil á dkplus.is undir Valmynd -> Stillingar -> Auðkenningar tákn"
                    type="info"
                />

                <div>
                    <div>Virkja DK tengingu?</div>
                    <Switch
                        onClick={() => setActive(!active)}
                        checked={active}
                        checkedChildren="JÁ "
                        unCheckedChildren="NEI"
                    ></Switch>
                </div>

                {active && (
                    <>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn API lykilinn',
                                },
                            ]}
                            label="DK token"
                            name="dk_token"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>
                    </>
                )}
            </Space>

            <Form.Item>
                <Button
                    loading={loading}
                    block
                    className="btn-success mt20"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
